// import Image from 'gatsby'
import React, { Component } from "react"
import Img from "gatsby-image"
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  Icon,
  Button,
  HStack,
  VStack,
} from '@chakra-ui/react';
// import ReactDOM from "react-dom";
// import default_image from "../../images/contact/user-default.jpg"
import {
  IoClose,
} from 'react-icons/io5';
import './map.scss'
import { url } from "inspector";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";

// import ContactUsMap from '../../images/map.svg'
// import ContactPopup from "./popup"

const hidePopups = () => {
  const elements: any = document.getElementsByClassName('locationPopup')
  for (let e of elements) {
    e.classList.add("hidden")
  }
  const activeRegions: any = document.getElementsByClassName('activeRegion')
  for (let r of activeRegions) {
    r.setAttribute('class', 'region')
  }
}

const TextSVG = () => {
  return <>
    <g>
      <g>
        <path className="st2" d="M209.3,161.1c1.3-1.3,2.4-2.8,3.5-4.2c11-13.8,22-27.7,33-41.5c0.1-0.1,0.2-0.3,0.3-0.4c6.1,0,12.2,0,18.3,0
			c-8.4,10.5-16.9,21-25.3,31.5c-0.1,0-0.2,0-0.2,0c0-1.1-0.1-2.2,0-3.3c0.1-0.8-0.3-1-1-0.9c-0.8,0.1-1.6,0.1-2.4,0
			c-0.7,0-0.9,0.2-0.9,0.9c0,2.7,0,5.3,0,8c0,0.8-0.2,1.4-0.7,1.9c-2.1,2.6-4.2,5.2-6.2,7.7c-0.1,0.2-0.3,0.3-0.2,0.5
			c-5.8,0-11.5,0-17.3,0C209.9,161.3,209.5,161.6,209.3,161.1z"/>
        <path className="st2" d="M265.2,161.3c-1.1-1.8-2.5-3.3-3.8-4.9c-4.5-5.7-9-11.3-13.6-16.9c-0.5-0.6-0.6-1,0-1.6
			c2.7-3.3,5.3-6.6,7.9-9.9c0.5-0.6,0.7-0.6,1.2,0c8.6,10.8,17.3,21.6,25.9,32.4c0.2,0.3,0.7,0.4,0.4,0.9
			C277.2,161.3,271.2,161.3,265.2,161.3z"/>
        <path className="st3" d="M253.5,153c-4.8,0-9.5,0-14.3,0c-0.2-0.3,0.1-0.5,0.2-0.6c2.1-2.8,4.2-5.7,6.4-8.5c0.2-0.3,0.4-0.8,0.9-0.2
			c2.3,3,4.6,6,6.8,9.1C253.6,152.8,253.5,152.9,253.5,153z"/>
        <path className="st3" d="M245.3,156.2c0,1.5,0.2,1.4-1.4,1.4c-1.9,0-1.6,0.3-1.6-1.8c0-1.4-0.2-1.2,1.8-1.2
			C245.5,154.5,245.3,154.4,245.3,156.2z"/>
        <path className="st3" d="M249,157.6c-1.9,0-1.6,0.2-1.6-1.7c0-1.4,0-1.4,1.3-1.4c1.8,0,1.8,0,1.8,1.2
			C250.4,157.6,250.4,157.6,249,157.6z"/>
        <path className="st3" d="M253.9,156c0,1.8,0.2,1.6-1.8,1.6c-1.4,0-1.2,0.3-1.2-1.7c0-1.3,0-1.3,1.9-1.3
			C253.9,154.6,253.9,154.6,253.9,156z"/>
        <path className="st3" d="M240.3,154.5c1.5,0,1.5,0,1.5,1.8c0,1.4,0.2,1.2-1.7,1.2c-1.3,0-1.3,0-1.3-1.7
			C238.8,154.3,238.6,154.6,240.3,154.5z"/>
        <path className="st3" d="M250.4,159.6c0,1.4,0,1.4-1.9,1.4c-1.4,0-1.1,0.1-1.1-1.8c0-1.4-0.2-1.1,1.8-1.2
			C250.4,158,250.4,158,250.4,159.6z"/>
        <path className="st3" d="M252.3,161c-1.5,0-1.5,0-1.4-1.9c0-1.3-0.3-1.1,1.9-1.1c1.3,0,1.1-0.1,1.1,1.8C253.9,161.2,254,161,252.3,161
			z"/>
        <path className="st3" d="M243.8,158c1.5,0,1.5,0,1.4,1.9c0,1.3,0.2,1.1-2,1.1c-1.3,0-1,0.2-1.1-1.8C242.1,157.6,243.1,158.1,243.8,158
			z"/>
        <path className="st3" d="M238.8,159.4c0-1.5-0.2-1.4,1.4-1.4c1.6,0,1.6,0,1.6,1.7c0,1.2,0,1.2-1.7,1.2
			C238.7,161,238.9,161.2,238.8,159.4z"/>
      </g>
      <text transform="matrix(1 0 0 1 293.8589 132.372)">
        <tspan x="0" y="0" className="st4 st5">We are proudly serving our </tspan>
        <tspan x="0" y="27.8" className="st4 st5">customers in Arizona, Idaho,</tspan>
        <tspan x="0" y="56.6" className="st4 st5">Nevada, Utah and Texas. </tspan>
      </text>
    </g>
  </>
}

const LocationsMap = (props: any) => {

  const doThings = (x: any) => {
    const obj = document.getElementById(x)
    if (obj) obj.classList.remove("hidden")
  }

  const showLocation = (state: string) => { hidePopups(); doThings(state) }
  // show_sw = () => { hidePopups(); this.doThings('pacificsw', 'PacificSW')}
  // show_great_lakes = () => { hidePopups(); this.doThings('greatlakes', 'GreatLakes')}

  const { locations } = props

  return (
    <HStack>
      <Box w={{ base: "200px", lg: "350px", xl:"550px" }} display={{ base: 'none',sm: 'block', md: 'block' }}>
        <StaticImage src="../../images/select-state.png" alt="" />
      </Box>
      <Box
        py="40px"
        style={{
          display: 'block',
          position: 'relative'
        }}>
        <HStack alignItems="baseline" pl={{base:"0px", lg:"260px"}}>
          <Box w="70px">
            <StaticImage src="../../images/acclaimedHW.png" alt="" />
          </Box>
          <VStack>
            <Text fontSize={{base:'md', lg: "xl"}} lineHeight="0.8">
              We are proudly serving our customers
            </Text>
            <Text fontSize={{base:'md', lg: "xl"}} lineHeight="0.8">
              in Arizona, Idaho, Utah, Nevada, and
            </Text>
            <Text fontSize={{base:'md', lg: "xl"}} lineHeight="0.8">
              Texas!
            </Text>
          </VStack>
        </HStack>


        {locations.map((location: any, index: number) => (
          <div key={`location-${index}`} id={location.slug} className="locationPopup hidden">
            <div className="popupContent">
              <div className="closePopup" onClick={() => hidePopups()}>
                <Icon as={IoClose} color={'black.500'} w={5} h={5} />
              </div>
              <Box
                maxW={'445px'}
                w={'full'}
                bg={'white'}
                boxShadow={'2xl'}
                rounded={'md'}
                p={6}
                overflow={'hidden'}>
                <Box
                  h={'110px'}
                  bg={'gray.100'}
                  mt={-6}
                  mx={-6}
                  mb={6}
                  pos={'relative'}
                  style={{
                    backgroundImage: `url(${location.locationBackground.file.url})`,
                    backgroundSize: 'cover',
                  }}
                >

                </Box>
                <Stack>
                  <Text
                    color={'green.500'}
                    textTransform={'uppercase'}
                    fontWeight={800}
                    fontSize={'sm'}
                    letterSpacing={1.1}>
                    {location.locationName}
                  </Text>
                  <Heading
                    // color={useColorModeValue('gray.700', 'white')}
                    fontSize={'2xl'}
                    fontFamily={'body'}>
                    Lower your risk of home ownership
                  </Heading>
                  <Text color={'gray.500'}>
                    When you buy a home in {location.locationName}, you are investing in your
                    life. But with this investment comes many
                    unknowns. You are suddenly responsible for
                    repairs and upkeep. When you are protected with a Home Warranty, Acclaimed has you covered.
                  </Text>
                </Stack>
                <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
                  {/* <Avatar
                src={'https://avatars0.githubusercontent.com/u/1164541?v=4'}
                alt={'Author'}
              /> */}
                  <Stack direction={'column'} spacing={3} fontSize={'sm'}>
                    {/* <Text fontWeight={600}>Choose a Homeowner Plan</Text> */}
                    <Button onClick={() => window.location.href = `/homeowner-plans/${location.slug}`}>Existing Homeowner Plans</Button>

                    <Text color={'gray.500'}>I'm ordering a plan for a home I currently reside in.</Text>
                    <p/>
                    <Button onClick={() => {
                      navigate("/real-estate-order", {
                        state: {
                          locationName: location.locationName
                        },
                      });
                      }}>Real Estate Transactions</Button>
                    <Text color={'gray.500'}>I'm ordering a warranty for a home that is being purchased as part of a real estate transaction.</Text>
                  </Stack>
                </Stack>
              </Box>
            </div>
          </div>
        ))}





        <svg version="1.1" className="location-map" id="location-map" xmlns="http://www.w3.org/2000/svg" x="0px"
          y="0px" viewBox="0 0 612 400.5">




          <g>
            <path className="usbg" d="M611.7,57.7l-1.4-2.4c-0.1-0.3-0.3-0.5-0.5-0.7l-1.2-1.3c-0.8-0.8-1.8-1.3-2.9-1.3c-0.1,0-0.2,0-0.3,0l0,0
		c0-0.5-0.2-0.9-0.3-1.4l-0.5-1.1c-0.6-1.4-2.1-2.3-3.6-2.3h-1l-5.1-16.9c-0.3-1-1.1-1.9-2-2.4l-1.2-0.6c-0.1,0-0.2-0.1-0.3-0.1
		l-3.9-1.6c-0.3-0.1-0.7-0.2-1-0.3l-0.8-0.1c-0.2,0-0.3,0-0.5,0c-1.1,0-2.1,0.4-2.8,1.2l-0.9,0.9l-0.9,0.6c-0.6-0.5-1.3-0.9-2.1-1
		l-0.6-0.1c-0.2,0-0.4-0.1-0.7-0.1h-0.7c-1.5,0-2.9,0.9-3.6,2.2l-1.3,2.6c-0.1,0.2-0.2,0.4-0.2,0.5l-1.9,5.7l-1.2,3
		c-0.2,0.5-0.3,1-0.3,1.6l0.1,3.1c0,0.1,0,0.2,0,0.3l0,0.2l-0.1,0.4c-0.6,0.7-0.9,1.6-0.9,2.6v3.8c0,0.9,0.3,1.7,0.8,2.4l-0.1,0.4
		l-1.5,2.8c-0.1,0.3-0.3,0.6-0.3,0.9l-0.5,1.8c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
		c-1.2,0.1-2.3,0.7-2.9,1.7l-0.7,1c-0.4,0.6-0.7,1.4-0.7,2.2l0,0.5l-5.1,1.3c-2.8,0.1-5.7,0.9-8.5,2.1l-0.6,0.2
		c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.3,0-0.4,0.1l-4.5,1.2c-0.1,0-0.2,0-0.2,0.1l-2.3,0.8l-1.4-0.1c-0.1,0-0.1,0-0.2,0
		c-0.3,0-0.6,0-0.9,0.1l-3.6,0.8c-0.2,0-0.4,0.1-0.6,0.2l-2,0.8c-0.4,0.1-0.7,0.3-1,0.6l-1.5,1.2c-0.2,0.1-0.4,0.3-0.5,0.5l-1.4,1.6
		c-0.1,0.1-0.1,0.2-0.2,0.2l-1.8,2.4c-0.1,0.1-0.1,0.2-0.2,0.3l-1.9,3c-0.1,0.1-0.1,0.2-0.2,0.3l-0.8,1.5l-0.1,0.1l-3.6,3.3
		c-0.9,0.8-1.4,2-1.3,3.2l0.1,1.5c0.1,0.8,0.4,1.6,0.9,2.3l0.5,0.6c0.3,0.3,0.5,0.6,0.9,0.8c0,0.3,0,0.6,0.1,0.8l0.1,0.4
		c-0.3,0.2-0.6,0.5-0.9,0.8l-1,1.2c-0.2,0.2-0.3,0.4-0.4,0.6l-0.8,1.4l-2.2,0.2c-0.4,0-0.9,0.1-1.3,0.3l-1.6,0.7
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6-0.3-1.3-0.5-1.9-0.5c0,0-4.2,0.1-4.2,0.1c-0.2,0-0.3,0-0.5,0l-2.1,0.3c-0.2,0-0.4,0.1-0.6,0.1
		l-2.6,0.8l-2.8,0.9c-0.3,0.1-0.7,0.3-0.9,0.4l-1.7,1.1c-1.5,1-2.2,2.9-1.6,4.6l1.2,3.7c0.2,0.6,0.5,1.2,1,1.6l-0.3,0.4
		c-0.3,0.4-0.5,0.8-0.6,1.3l-0.2,0.2l-0.8,0.5c-0.5,0.3-1,0.8-1.3,1.3l-0.3,0.5l-0.3,0.3l-0.7,0.5c-0.2,0.2-0.4,0.4-0.6,0.6
		c0,0-2.9,2.7-2.9,2.7l-0.3,0.1c-0.1,0.1-0.3,0.2-0.4,0.2l-2.7,1.8c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-3.6,2.4-3.6,2.4l-2.5,1.4
		c-0.4,0.2-0.7,0.5-1,0.8l-2.1,2.3l-1.8,1.7l-1.1,0.2l-1.9,0.4c-0.3,0.1-0.6,0.2-0.9,0.3l-2.3,1.1l-0.8-0.7c-0.7-0.6-1.7-1-2.6-1
		c-0.2,0-0.3,0-0.5,0l-2,0.2l-0.6-0.3l-0.6-0.4c0.2-0.5,0.3-1,0.3-1.6l0-1.2c1-0.6,1.7-1.7,1.8-2.9c0.1,0,0.3-0.1,0.4-0.2l1.1-0.5
		c1.5-0.7,2.4-2.2,2.3-3.8l-0.2-6.1c0-0.5-0.1-0.9-0.3-1.3l-2-5.2l-1.4-5.7c-0.1-0.5-0.3-0.9-0.6-1.3l-1.5-2.1
		c-0.2-0.3-0.5-0.6-0.9-0.9l-1.5-1.1c-0.7-0.5-1.5-0.8-2.4-0.8c-0.4,0-0.9,0.1-1.3,0.2l-0.2-6.1c0-0.8-0.3-1.6-0.7-2.2l-0.4-0.5
		c0.1-0.2,0.1-0.5,0.2-0.7l0.1-0.7c0.2-1.3-0.3-2.6-1.2-3.5l-1-0.9l-0.5-1c-0.7-1.4-2.1-2.2-3.6-2.2h-1l-0.1,0
		c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.1,0.5-0.3,0.7-0.4l0.8-0.6c1-0.8,1.6-1.9,1.6-3.2v-0.6c0-0.5-0.1-0.8-0.1-1.1
		c-0.1-0.4-0.2-0.8-0.3-1c-0.1-0.2-0.1-0.3-0.2-0.5l-0.1-0.2c-0.2-0.4-0.4-0.7-0.7-1c-0.1-0.1-0.1-0.2-0.2-0.3l-0.4-0.6
		c-0.8-1.1-2-1.7-3.3-1.7c-0.1,0-0.3,0-0.4,0l-1.5,0.2v-1c0-1.8-1.1-3.3-2.8-3.8l-1.3-0.4c-0.4-0.1-0.8-0.2-1.2-0.2
		c-1,0-2.1,0.4-2.8,1.2l0,0l-1.2,0.3c-0.1-2-1.7-3.6-3.7-3.8l-1.3-0.1c-0.1,0-0.2,0-0.3,0c-0.7,0-1.3,0.2-1.9,0.5l-1.6,0.8l-2.1,0.9
		l-3.5,0.2c-0.3,0-0.7,0.1-1,0.2l-2.3,0.7c-0.6,0.2-1.1,0.5-1.5,0.8l-2.3,2c0,0,0,0,0,0c-0.7-0.5-1.5-0.7-2.3-0.7
		c-0.7,0-1.4,0.2-2,0.5l-0.6-1.2c-0.3-0.5-0.6-1-1.1-1.3l-1-0.8c-0.4-0.3-0.8-0.5-1.2-0.7c0.8-0.5,1.4-1.2,1.7-2.1l0.3-0.9
		c0.5-1.4,0.1-3-1-4.1l-0.9-0.9c-0.8-0.8-1.8-1.2-2.8-1.2c-0.1,0-0.2,0-0.4,0l-3.2,0.3c-0.5,0-1,0.2-1.5,0.5l-3,1.6
		c-0.3,0.2-0.7,0.4-0.9,0.7l-1.6,1.6c-0.1,0.1-0.3,0.3-0.4,0.5l-0.1,0.2l-0.2,0.1c-0.7,0.3-1.4,0.8-1.8,1.5l-1.3,2
		c0,0-0.1,0.1-0.1,0.2l-1.1,0.5c-0.3,0.1-0.5,0.3-0.8,0.5l-0.8,0.6l-2.9,0.4c-0.8,0.1-1.6,0.5-2.2,1.1l-0.6,0.6
		c-0.3,0.3-0.5,0.5-0.6,0.9c-0.3-0.8-0.8-1.6-1.5-2.1l-1-0.7c-0.5-0.4-1.1-0.6-1.7-0.7l0.8-1.1l1.2-1.1l2.6-1.1l6.1-2.7l0.7-0.3
		l0.1,0.1c0.7,0.5,1.5,0.7,2.3,0.7c0.5,0,0.9-0.1,1.4-0.3L385,62c0.2-0.1,0.5-0.2,0.7-0.3l2.1-1.3c0.3-0.2,0.5-0.4,0.7-0.6l3.3-3.3
		c0.1-0.1,0.3-0.3,0.4-0.4l0.3-0.4c1.3-1.7,1-4.1-0.6-5.5l-0.6-0.5c-0.7-0.6-1.6-0.9-2.6-0.9c-0.3,0-0.6,0-1,0.1l-1.6,0.4
		c-0.5,0.1-1,0.3-1.4,0.6l-3.1,2.2l-0.9,0.3c0,0-0.1,0-0.1,0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0l-1.6,0.1
		c-0.7-0.8-1.8-1.2-2.9-1.2c0,0-5,0.2-5,0.2c-0.8-0.9-1.8-1.4-3-1.4c-0.2,0-0.4,0-0.6,0l-1.4,0.2c-0.9,0.1-1.7,0.5-2.3,1.1l-1.2,1.2
		c-0.6-0.8-1.4-1.4-2.5-1.6l-0.3-0.1c-0.3-0.8-1-1.6-1.8-2c-0.6-0.3-1.3-0.5-2-0.5c-0.5,0-1,0.1-1.5,0.3c0,0-0.5,0.2-0.6,0.2l-2.3-1
		l-2.2-1.5c-0.7-0.5-1.4-0.7-2.2-0.7H346c-0.6-0.4-1.4-0.6-2.1-0.6c-0.3,0-0.6,0-0.9,0.1l-1.3,0.3c-0.6,0.1-1.1,0.4-1.6,0.8
		c-0.5-0.3-1.2-0.5-1.8-0.5l-1.8-0.1c-0.7-0.7-1.7-1.1-2.8-1.1h-0.8l-0.5-2.1l-0.5-3.3c-0.2-1.5-1.2-2.7-2.6-3.2l-1.1-0.4
		c-0.2-0.1-0.4-0.1-0.6-0.2l-1.5-0.3c-0.3-0.1-0.5-0.1-0.8-0.1h-0.1c-2.1,0-3.9,1.7-4,3.8l-0.1,1.7h-14.8c-0.4-0.2-0.9-0.3-1.4-0.3
		L286.2,40L274,39.6l-17.3-0.8l-14.1-1.3c-0.3-0.1-0.6-0.2-0.9-0.2l-20.1-1.8l-19.2-2.4l-19.1-2.6L162,27.1l-12-2.3l-19.3-4
		c-0.5-0.4-1.2-0.7-1.9-0.9l-9.9-1.8c-0.2,0-0.5-0.1-0.7-0.1c-0.3,0-0.6,0-0.9,0.1l-7.6-1.6l-15-3.7L81.6,9.1c-0.1,0-0.1,0-0.2,0
		l-5.5-1.3l-6.2-1.7l-2.8-1C66.5,5,66,4.9,65.6,4.9c-0.8,0-1.6,0.3-2.3,0.7l-0.7,0.5c-0.6,0.4-1.1,1.1-1.4,1.8L61,8.4
		c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0L59,8.8c-1.2,0.1-2.2,0.7-2.9,1.6
		c-0.7,1-0.9,2.2-0.6,3.3l0.2,0.8l-2.2-1l-1.6-1l-1.3-1.3l-1.5-1.7c-0.6-0.7-1.4-1.1-2.2-1.3L45.4,8c-0.3-0.1-0.6-0.1-0.8-0.1
		c-1.8,0-3.4,1.2-3.9,3.1l-1.5,6.2c-0.2,1-0.1,2,0.4,2.9l0.7,1.2V25L40,26.5c-0.1,0.5-0.1,1.1,0,1.7l0.8,3.9
		c-0.5,0.6-0.8,1.4-0.8,2.2l-0.1,2.4c0,0.3,0,0.6,0.1,0.9c-0.7,0.6-1.1,1.5-1.2,2.5l-0.2,1.9c-0.1,0.5,0,1.1,0.1,1.6
		c0,0-1.6,4.5-1.6,4.5L35,54.3l-2,3.9c-0.1,0.1-0.1,0.3-0.2,0.5l-3.2,9.1l-4,8.5l-5.1,8l-1.3,1.8c-0.4,0.5-0.6,1.2-0.7,1.8l-0.6,5.7
		c0,0.2,0,0.4,0,0.6l0.2,5.6c-0.1,0.1-0.1,0.3-0.1,0.4l-1.1,3.6c-0.1,0.4-0.2,0.7-0.2,1.1l-0.1,4.1l-2.6,6l-1.3,1.1
		c-0.2,0.2-0.5,0.4-0.6,0.7c-0.9,0.5-1.6,1.3-2,2.3l-1,3c0,0.1-0.1,0.2-0.1,0.3l-0.6,2.3c-0.3,1.1-0.1,2.3,0.5,3.2l1.7,2.5l0.8,2.2
		l0.5,1.5l-0.1,2.3l-0.6,1.1c-0.3,0.5-0.4,1-0.5,1.5l-0.5,3.7l-0.6,2.3c-0.2,0.9-0.2,1.9,0.2,2.7l1.3,2.8c0.1,0.1,0.1,0.3,0.2,0.4
		l1.7,2.7l1.3,2.8l0.5,1.5l0,0.2c-0.2,0.5-0.3,1-0.3,1.5v1.7c0,1,0.4,1.9,1,2.7l2.3,2.5l-0.1,0.5l-0.4,1.2c-0.2,0.5-0.2,0.9-0.2,1.4
		l0.1,5.6c0,0.7,0.2,1.3,0.5,1.9l1.5,2.6c0.1,0.1,0.2,0.3,0.2,0.4l1.1,1.5c-0.4,0.5-0.8,1.1-0.9,1.8l-0.6,3c-0.1,0.5-0.1,1,0,1.5
		l0.4,2.1c0.1,0.6,0.3,1.1,0.7,1.6l1.7,2.4l1.8,6c0,0.1,0.1,0.3,0.2,0.4l0.9,2.1c0.1,0.1,0.1,0.3,0.2,0.4l2.2,3.8l0.5,1l0.3,2
		c0.1,0.8,0.4,1.5,0.9,2l-0.9,3.6l-0.3,1.1c-0.4,1.3,0,2.7,0.9,3.7l1.5,1.7c0.7,0.7,1.6,1.2,2.6,1.3l2.2,0.2l2.3,0.9l2.3,1.2
		c0.6,0.3,1.2,0.5,1.8,0.5h0.2l0.5,0.5l1.4,2.7l0.7,1.4c0.4,0.8,1,1.4,1.7,1.8l2.5,1.3c0.3,0.2,0.7,0.3,1.1,0.4l0.6,0.1
		c-0.1,0.7-0.1,1.4,0.1,2.1l0.1,0.3c0.4,1.3,1.5,2.3,2.8,2.6l2,0.5c0.2,0.1,0.5,0.1,0.7,0.1l1.7,0.1c0.1,0,0.2,0,0.2,0
		c0.5,0,0.9-0.1,1.4-0.2l0,0l0.2,0.7c0.1,0.3,0.2,0.6,0.4,0.8l1.3,2.1l0.2,1.1v5.9c0,0.3,0,0.5,0.1,0.8l0.2,1
		c0.3,1.7,1.7,2.9,3.4,3.2l6.5,0.9l12.9,1.8c0.1,0,3.6,0.4,3.6,0.4c0.1,0.4,0.2,0.9,0.4,1.3l0.4,0.8c0.4,0.7,0.9,1.3,1.6,1.7l12.4,7
		l7.9,4.9c0,0,9.8,5.6,9.8,5.6l11,6.6c0.4,0.2,0.8,0.4,1.2,0.5l8.2,1.7c0.1,0,0.3,0,0.4,0.1l16.6,1.8c0.1,0,0.3,0,0.4,0
		c0,0,0.1,0,0.1,0l9.6,1.3c0.2,0,0.4,0,0.5,0c2,0,3.7-1.5,4-3.5l0.4-2.7l14.9,1.5l0,0l0.7,2.1c0.3,1,1.1,1.8,2,2.3l1.7,0.8l0.2,0.6
		c0.2,0.4,0.4,0.8,0.7,1.2l4.6,5.2l0.9,1c0.4,0.5,0.9,0.9,1.5,1.1l1.9,0.8l0,0.1c0.2,0.5,0.6,0.9,0.9,1.2c0.1,0.4,0.2,0.7,0.3,1
		l1.7,3.5v4.3c0,0.6,0.1,1.2,0.4,1.7l1.5,3.2c0.2,0.4,0.4,0.7,0.7,1l4.9,5.2c0.4,0.4,0.8,0.7,1.3,0.9l2.5,1.1c0.3,0.8,0.9,1.5,1.7,2
		l2.7,1.5c0.2,0.1,0.5,0.2,0.8,0.3l0.8,0.2l0.6,0.4c0.2,0.2,0.5,0.3,0.8,0.4l1.9,0.7c0.5,0.2,0.9,0.2,1.4,0.2c1.1,0,2.1-0.4,2.9-1.3
		l1.7-1.8c0.1-0.1,0.3-0.3,0.4-0.5l2.8-4c0.3-0.4,0.5-0.8,0.6-1.2l0.5-1.8l0.3-0.5l2-0.8l0.2,0.2c0.6,0.4,1.3,0.7,2,0.7l4.7,0.4
		l2.9,0.5c0.1,0.3,0.3,0.6,0.6,0.9l1.8,2.1c0.1,0.1,0.2,0.3,0.4,0.4l2.7,2.4c0.2,0.6,0.5,1.1,0.9,1.6l0.4,0.5l0.3,1.6
		c0.1,0.3,0.1,0.6,0.3,0.9l3.1,7.2l0,0.4c-0.1,1.5,0.6,2.9,1.9,3.7l1.8,1.1l1.8,3.4c0.1,0.2,0.3,0.4,0.4,0.6l2.3,2.9
		c0.4,0.5,0.9,0.9,1.6,1.2l-0.1,0.3c-0.3,1.1-0.1,2.2,0.5,3.2l0.2,0.3c-0.3,0.8-0.3,1.6,0,2.4l0.5,1.7c0.3,0.9,0.9,1.8,1.7,2.3
		l0.6,0.4l0.5,2.4c0.1,0.4,0.2,0.8,0.4,1.2l1.5,2.7c0.4,0.8,1.1,1.4,1.9,1.7l5.1,2.3c0.2,0.1,0.5,0.2,0.7,0.2l2.6,0.6l2,1.5
		c0.5,0.3,1,0.6,1.5,0.7l2.3,0.5c0.3,0.1,0.6,0.1,0.8,0.1c0.4,0,0.8-0.1,1.1-0.2l1.6,0.3l3.1,2.1c0.7,0.5,1.5,0.7,2.3,0.7
		c0.8,0,1.5-0.2,2.2-0.7l2.4-1.6c0.4-0.3,0.8-0.7,1.1-1.1l0.8-1.2c0.9-1.3,0.9-3,0.1-4.4l-0.8-1.2l-0.5-3.2c0-0.1,0-0.3-0.1-0.4
		l-1.2-4.6c0-0.1,0-0.2-0.1-0.3l-0.2-0.5l0.3-1.7l1.5-5.3c0,0,1-3.9,1-3.9c0-0.1,0-0.2,0.1-0.3c0.3-0.2,0.7-0.6,0.9-0.9l1.7-2.3
		l0.5-0.1c0.5-0.1,0.9-0.3,1.3-0.5l4-2.4c0.2-0.1,0.3-0.2,0.5-0.3l0.8-0.7l3.6-1.9l3.6-1.5c0.2-0.1,0.3-0.2,0.5-0.3l3.5-2.1
		c0.1-0.1,0.3-0.2,0.4-0.3l1.8-1.4c0.1-0.1,0.2-0.2,0.3-0.3l3.4-3.3l2-1.3c0.1-0.1,0.2-0.1,0.2-0.2l1.7-1.3c0.5-0.4,0.8-0.8,1.1-1.3
		l4.7-2.2l3.6-1c0.7-0.2,1.2-0.5,1.7-1l0.2-0.1l6.7,0.1l6.2,2.1c0.2,0.1,0.4,0.1,0.6,0.2l4.2,0.7c0.2,0,0.4,0.1,0.7,0.1
		c0.5,0,1-0.1,1.5-0.3l1.1-0.4l0.8,0.3c0.1,0,2.1,0.7,2.1,0.7c0.4,0.1,0.8,0.2,1.2,0.2c0.9,0,1.8-0.3,2.5-0.8c0.1,0,0.1,0,0.2,0
		c0.1,0.8,0.5,1.6,1.1,2.2l0.8,0.8c0.4,0.4,0.8,0.7,1.3,0.9l5.4,2.3c0.5,0.2,1,0.3,1.6,0.3c0.6,0,1.1-0.1,1.7-0.4l2.2-1
		c0.7,0.5,1.6,0.9,2.5,0.9c0.5,0,1-0.1,1.4-0.3l1.3-0.5c0.1,0,0.1-0.1,0.2-0.1l0.5-0.2c0.7-0.3,1.4-0.8,1.8-1.5l1.2-1.8
		c0.2,0,0.4,0.1,0.7,0.1l0.6,0.1l1.2,0.6c0.7,1.1,2,1.8,3.3,1.8h0.6c0.6,0.3,1.3,0.5,1.9,0.5c0.1,0,0.3,0,0.4,0
		c1.1-0.1,2.1-0.7,2.7-1.5l1.3-1.7c0.5-0.7,0.8-1.6,0.8-2.4v-0.6c0-1.9-1.4-3.5-3.2-3.9l-1.2-1c-0.4-0.3-0.9-0.6-1.4-0.7l0.8-1.4
		c0.7-1.3,0.7-2.9-0.2-4.2l-0.3-0.4l-0.1-0.7c0-0.3-0.1-0.6-0.2-0.9l1.4-0.4l0.3,0.2c0.6,0.3,1.2,0.4,1.8,0.4h3.8
		c0.6,0,1.1-0.1,1.6-0.3c0.2,0,0.3,0,0.5,0c0.3,0,0.6,0,1-0.1c0.7,1,1.7,1.7,3,1.8l1.1,0.1c0.1,0,0.2,0,0.4,0c0.6,0,1.1-0.1,1.6-0.4
		l3.1-1.4c0.2-0.1,0.5-0.2,0.7-0.4l0.7-0.1c0.2,0,0.4-0.1,0.6-0.2l3.4-1.2l3.5-0.3c0.1,0,0.2,0,0.2,0l2.2-0.3l3.9,0.9l4.5,2.2
		l0.7,0.6c0.3,0.3,0.7,0.6,1.1,0.7l0.8,0.3l0,0c0.1,1.3,0.8,2.4,1.8,3.1c0.7,0.4,1.4,0.6,2.2,0.6c0.5,0,0.9-0.1,1.4-0.2l1.2-0.5h1.8
		c0.6,0,1.3-0.2,1.8-0.5l2.3-1.2c0.3-0.2,0.6-0.4,0.9-0.7l1.3-1.2c0,0,0.4,0,0.5,0c1.5,0,2.9-0.9,3.6-2.2l0.2-0.4
		c0.1-0.2,0.2-0.3,0.2-0.5l1,0.5c0.1,0.1,0.3,0.1,0.4,0.2l1.6,0.6l0.9,1.3c0.5,0.7,1.1,1.2,1.9,1.5l0.1,0.3c0.3,1.1,1.1,2,2.2,2.4
		l1.1,0.5l0.5,0.7c0.5,0.8,1.2,1.3,2.1,1.6l1.2,0.4c0.1,0,0.2,0.1,0.3,0.1l1.6,0.4l0.1,0.3c0.2,0.4,0.4,0.7,0.6,1l1.2,1.4v4.5
		l-0.8,2.5c-0.2,0.5-0.2,1.1-0.2,1.7l0.2,1.7c0,0.2,0.1,0.3,0.1,0.5l0.8,3.2c0.1,0.2,0.1,0.5,0.2,0.7l1.1,2.4
		c-0.2,1.2,0.2,2.5,1.1,3.4l2.1,2.2l1.4,1.6l1.7,3.1c0.1,0.2,0.2,0.3,0.3,0.5l1.6,2.2l1.1,2.7c0.5,1.2,1.6,2.1,2.8,2.4l0.9,0.2
		c0.3,0.1,0.6,0.1,0.9,0.1c0.2,0,0.4,0,0.5,0l0.2,1.2c0.1,0.6,0.3,1.2,0.7,1.7l1.8,2.5c0.8,1,2,1.7,3.2,1.7c0.1,0,0.1,0,0.2,0
		c0.6,0,1.2-0.2,1.8-0.5l0.7,0.5l1.4,2.2l0.3,1.4l0.2,1.6c0.1,1,0.6,2,1.4,2.6l0.1,0.1c-0.2,0-0.4-0.1-0.5-0.1
		c-0.8,0-1.6,0.3-2.3,0.7l-1.4,1c-0.4,0.3-0.7,0.6-1,1c-0.1,0-0.2,0-0.3,0.1l-0.9,0.3c-1.3,0.4-2.3,1.5-2.6,2.9
		c-0.3,1.3,0.1,2.8,1.1,3.7l0.2,0.2c0.8,0.8,1.8,1.2,2.8,1.2c0.8,0,1.6-0.2,2.3-0.7l0.3-0.2c0.3,0,0.6-0.1,0.9-0.2l4-1.4
		c0.5-0.2,0.9-0.4,1.2-0.7c0.1,0,0.2,0,0.4,0c0.8,0,1.6-0.2,2.3-0.7l1.7-1.2c0.1,0,0.1-0.1,0.2-0.1l1.4-1.1c0.1-0.1,0.3-0.2,0.4-0.4
		l1.7-1.3c0.2-0.1,0.3-0.2,0.4-0.4l2.4-2.5c0.4-0.4,0.7-0.9,0.9-1.4l1.5-4l0.5-0.8c0.3-0.5,0.4-1.1,0.5-1.7l0.1-1.7
		c0.1-1-0.3-2-0.9-2.8c0.2-0.4,0.3-0.7,0.3-1.2l0.2-2c0.1-0.6,0-1.1-0.2-1.7l-0.2-0.7l-0.3-7.3c0-0.2,0-0.3,0-0.5l-0.8-4.9
		c-0.1-0.5-0.2-0.9-0.5-1.3l-3-5.4c0,0-2.3-3.8-2.3-3.8l-1.6-3.3c-0.2-0.4-0.5-0.8-0.8-1.1l-1.3-1.3l-0.9-2.3
		c0.9-1.3,0.9-2.9,0.1-4.3l-1-1.7c-0.2-0.3-0.4-0.6-0.7-0.9l-2.5-2.3l-2.8-3.2l-2.3-3.8l-3.4-6l-2.3-6l-1-3.2c0-0.3,0-0.6,0-0.8
		l-0.4-3l1.3-5.7l0.9-2.4c0.2-0.7,0.3-1.4,0.2-2.1l-0.1-0.4l1.5-2.9c0.2-0.4,0.3-0.8,0.4-1.2c0,0,0.1-0.1,0.1-0.2l3.1-3.5
		c0.5-0.5,0.8-1.2,0.9-1.9c0.1-0.1,0.2-0.2,0.3-0.3l1.9-1.8l0.5-0.2c0.6-0.3,1.1-0.8,1.5-1.3l1.3-1.9l1.5-1.5
		c0.2-0.2,0.5-0.5,0.6-0.8l0.6-1.1l0.1,0c1.1-0.4,2.1-1.2,2.5-2.3l0.2-0.5c1-0.7,1.7-1.8,1.8-3l0.2-3.2l1.7-3.3l2-2.1l2.2-0.9
		l2.3-0.2c1.7-0.2,3.1-1.4,3.5-3.1l0.3-1.2l1.1-3.5l1.6-2.1l3.5-2.9l2.6-1.2l0.4-0.1c0.4,0.1,0.7,0.2,1.1,0.2h0.7
		c1.4,0,2.7-0.7,3.4-1.9l1.9-3.1c0,0,0.1-0.1,0.1-0.1l1-1.8c0.9-1.5,0.6-3.4-0.6-4.7c-0.1-0.1-0.2-0.2-0.3-0.3
		c0.5-0.4,0.9-0.9,1.2-1.5l0.8-1.8c0-0.1,0.1-0.2,0.1-0.2l0.2-0.1c1.3-0.6,2.2-1.9,2.3-3.3l0.1-0.8c0.1-0.1,0.3-0.3,0.4-0.5
		c0.7-1,0.9-2.2,0.6-3.4l-0.3-1.1c-0.2-0.7-0.5-1.3-1-1.8l-1.2-1.2l-2-3.6l-1.2-2.8c-0.3-0.7-0.7-1.2-1.3-1.6l-1.2-2.4
		c0-0.1-0.1-0.1-0.1-0.2c0.4-0.4,0.7-0.9,0.9-1.4l1.2-3.2c0.2-0.6,0.3-1.3,0.2-1.9l-0.2-1.7l0.1-1.5l1.2-1.6
		c0.3-0.4,0.6-0.9,0.7-1.4l0.1-0.5c0.2-0.2,0.4-0.5,0.5-0.8l1.2-2.5c0.3-0.7,0.4-1.4,0.4-2.1l-0.3-2.9c0-0.3-0.1-0.6-0.2-1
		c0-0.2-0.1-0.4-0.1-0.6l-0.9-2.8l0.9-1.1c0.5-0.6,0.8-1.2,0.9-2l0.4-2.2l1.4-1.9c0.1-0.2,0.2-0.4,0.3-0.6l2-4.2
		c0.1-0.3,0.2-0.6,0.3-0.9l0.7-3.5c0.2-0.9,0.1-1.8-0.3-2.6l-0.1-5.2c0-0.4-0.1-0.9-0.2-1.3l3.2-1.1l1.7-0.6
		c0.4-0.1,0.8-0.3,1.1-0.6l4.5-3.5l2.6-1.9c0.2-0.2,0.4-0.3,0.6-0.5l1.9-2.1c0.9-1,1.2-2.3,0.9-3.6c-0.1-0.6-0.4-1.2-0.8-1.6
		l0.9-0.3c0.5-0.2,1-0.4,1.4-0.8l1.5-1.3c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.1,0.6-0.2,0.8-0.4l1.1-0.6c0.1,0.1,0.2,0.3,0.4,0.4l0.4,0.3
		c0.7,0.5,1.5,0.8,2.4,0.8c0.6,0,1.1-0.1,1.7-0.4l1.2-0.5l1-0.4c0.6,0.4,1.3,0.6,2.1,0.6c0.2,0,0.3,0,0.5,0l1.7-0.2
		c0.3,0,0.7-0.1,1-0.3l0.5-0.2c1-0.4,1.8-1.2,2.2-2.2c0.4-1,0.4-2.1-0.1-3.1l-0.5-1.1c-0.2-0.5-0.5-0.9-0.9-1.2
		c0.2-0.6,0.3-1.3,0.2-2l-0.2-1.4c0-0.2-0.1-0.4-0.1-0.6l-0.6-1.9c-0.1-0.5-0.4-0.9-0.7-1.3l-1.2-1.5c-0.4-0.5-0.9-0.9-1.5-1.1
		l-1.1-0.5c-0.5-0.2-1.1-0.4-1.7-0.4c-0.1,0-0.2,0-0.3,0l-1.2,0.1c-0.9,0.1-1.7,0.4-2.4,1.1l-0.3-0.3c-0.4-0.4-0.8-0.8-1.3-1
		l0.2-0.5c0.5-1.1,0.5-2.3,0-3.4l2.5-12l0.2-0.1c0.1,0,0.2-0.1,0.3-0.1l3.8-2c0.9-0.5,1.5-1.2,1.9-2.2c0.7-0.3,1.3-0.8,1.8-1.5
		l1-1.5c0.2-0.3,0.4-0.6,0.5-0.9c0.1,0,0.3,0,0.4,0c1.9,0,3.6-1.4,3.9-3.3l0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7c0,0,0.8,0,0.8,0
		c1.1,0,2.2-0.5,3-1.3l3-3.3l1.5-1.4l0.4-0.1c1.1-0.4,1.9-1.1,2.4-2.2l1.3-2.9c0.2-0.5,0.3-1.1,0.3-1.6v-1.2
		C612.2,59,612,58.3,611.7,57.7z M416.7,92.3l-0.2,0.8c-0.5,0.2-1,0.6-1.4,1l-1.1,1.2c-0.5,0.5-0.8,1.2-1,1.9l-0.1,0.5
		c0,0.1-0.1,0.3-0.1,0.4c-0.9,0.7-1.5,1.9-1.5,3.1v0.5c0,0.4,0.1,0.8,0.2,1.2l0.2,0.6l-0.5,2.8l-0.9,2.3c-0.2,0.6-0.3,1.3-0.2,2
		l0.4,3c0,0.1,0,0.2,0,0.2l-0.1,0.3l-0.2,0.4c-0.1,0.3-0.2,0.7-0.3,1l-0.2,1.7c-0.1,0.9,0.1,1.7,0.5,2.5l2.2,3.7l1.7,3.9l0.7,2.2
		l-0.4,2.2l-0.5,3.4l-1.4,2.9c-0.2,0.4-0.3,0.8-0.4,1.3l0,0.4l-0.6,0.6c-0.1,0.1-0.1,0.1-0.2,0.2l-1.4,0.8c-0.2,0.1-0.4,0.3-0.6,0.4
		l-0.1-0.3c0,0-0.8-2.5-0.8-2.5c-0.2-0.5-0.4-0.9-0.7-1.3l-0.8-0.9l-0.3-0.6l-0.1-2.4c0,0,0-2,0-2c0-0.4,0-0.7-0.1-1.1l-0.7-2.6
		l-0.4-3.8c0-0.5-0.2-1-0.4-1.4l-0.1-0.1l0.2-0.5c0-0.1,0.1-0.2,0.1-0.2l0.4-1.5l0.7-1.3c0.4-0.8,0.6-1.7,0.4-2.6l-0.4-2l-0.2-1.4
		l0.6-0.7c0.5-0.6,0.8-1.4,0.9-2.3l0.1-1.6c0-0.6-0.1-1.2-0.3-1.7l0-0.1c0.1-0.5,0.2-1,0.1-1.5l-0.2-1.6l1.3-2.8c0,0,2-4.5,2-4.5
		c0.2-0.4,0.3-0.9,0.3-1.3l0.1-1.4c0-0.5,0-1-0.2-1.5l-0.1-0.3c-0.2-0.7-0.7-1.4-1.3-1.9c0.4,0.1,0.9,0.2,1.3,0.2c1,0,1.9-0.4,2.7-1
		l0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.3l0.7-0.6c0.5-0.4,0.9-1,1.1-1.6l3.3-0.3c1-0.1,2-0.6,2.6-1.3c0,0,0.1-0.1,0.1-0.1
		c-0.3,0.6-0.4,1.3-0.3,2l0.1,1.1c0,0-0.8,0.8-0.8,0.8c-0.3,0-0.5,0.1-0.8,0.2l-1,0.4C417.9,89.9,417,91,416.7,92.3z"/>
            <path className="usbg" d="M229.1,375.1l-1.7-1.2l-0.8-1.8c-0.1-0.2-0.2-0.4-0.3-0.6l-1-1.5c-0.3-0.5-0.7-0.9-1.2-1.2l-2.4-1.5
		c-0.1-0.1-0.3-0.2-0.4-0.2l-7.3-3.4c0.3-0.6,0.5-1.2,0.5-1.8v-1.6c0-1.4-0.7-2.6-1.9-3.4l-3-1.9c-0.3-0.2-0.7-0.4-1.1-0.5l-2.5-0.7
		l-2.8-1.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.6-0.6-1.4-0.9-2.3-1l-3.7-0.4c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.9,0.1l-1.7-0.3
		c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.4,0.2c-0.3-0.7-0.9-1.3-1.5-1.7l-1.2-0.7l-0.8-1.5c0-0.1-0.1-0.1-0.1-0.2l-1.5-2.5
		c-0.7-1.2-2.1-1.9-3.4-1.9c-0.6,0-1.2,0.1-1.8,0.4l-4.1,2.1c-0.9,0.5-1.6,1.3-1.9,2.2l-0.5,1.4c-0.4,1.2-0.3,2.5,0.4,3.6l2.6,3.9
		c0.7,1.1,2,1.8,3.3,1.8c0.2,0,0.3,0,0.5,0l1-0.1c0.6,0.3,1.2,0.4,1.8,0.4c0.1,0,0.2,0,0.3,0l2.7-0.2c0.5,0,1-0.2,1.5-0.4
		c0.5,0.9,1.3,1.7,2.4,2l0.1,0c0,0.6,0.1,1.1,0.4,1.6l1.4,2.8c0.7,1.4,2.1,2.2,3.6,2.2c0.5,0,1-0.1,1.5-0.3l1.6-0.6l0,0.1
		c0.3,0.4,0.7,0.8,1.1,1l1.8,1.1c0.6,0.4,1.4,0.6,2.1,0.6c0.2,0,0.5,0,0.7-0.1l1.5-0.3c-0.1,0.5-0.1,0.9-0.1,1.4
		c0,0,0.2,1.7,0.2,1.7c-1.4,0.7-2.3,2.2-2.2,3.8l0.1,2c0.1,1.1,0.6,2.1,1.4,2.8l0.5,0.4l0.4,1.4l-0.1,0.6c-0.1,0.4-0.1,0.8-0.1,1.2
		l0.2,3.1c0.1,1.2,0.7,2.3,1.7,3l2.5,1.8c0.6,0.4,1.3,0.7,2.1,0.7l1.4,0.1c0.1,0,0.2,0,0.3,0c1.2,0,2.3-0.5,3.1-1.5l2.7-3.3
		c0.1,0,0.2,0,0.3,0c0.5,0,0.9-0.1,1.3-0.2l2.8-1c0.4-0.2,0.8-0.4,1.2-0.7l2.7-2.2c0.6-0.5,1.1-1.2,1.3-2l0.3-1.1
		C231.2,377.8,230.6,376,229.1,375.1z"/>
            <path className="usbg" d="M168.5,338.6c-0.3-1.7-1.8-3-3.5-3.2l-3-0.3c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l-2.8,1.2
		c-0.9,0.4-1.6,1.1-2,2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.7,0.1l-1.8,0.3c-1.2,0.2-2.3,1-2.9,2.1l-1.5,2.8
		c-0.7,1.2-0.6,2.7,0.1,3.9c0.7,1.2,2,1.9,3.4,1.9h2.2c1.4,0,2.7-0.7,3.4-1.9l0.4-0.7l3.5,1.5c0.5,0.2,1,0.3,1.5,0.3
		c0.2,0,0.3,0,0.5,0l1.6-0.2c1.5-0.2,2.7-1.1,3.2-2.5l1.1-2.8c0.3-0.7,0.3-1.5,0.2-2.2L168.5,338.6z"/>
            <path className="usbg" d="M8.7,340.3c0.8,1.1,2,1.7,3.3,1.7c0.8,0,1.6-0.2,2.3-0.7l1.6-1.1c0.9-0.6,1.5-1.5,1.7-2.6
		c0.2-1.1-0.1-2.1-0.7-3l-0.8-1.1l-0.7-1.6c-0.6-1.4-2.1-2.4-3.6-2.4h-1.5c-2.2,0-4,1.8-4,4v1.9c0,0.8,0.2,1.6,0.7,2.3L8.7,340.3z"
            />
            <path className="usbg" d="M21.5,326.1l0.5,1.2c0.6,1.3,1.8,2.2,3.2,2.4L28,330c0.1,0,0.3,0,0.4,0c1.8,0,3.4-1.2,3.9-3l0.3-1.2
		c0.2-0.8,0.1-1.6-0.1-2.4l-1.5-3.9c-0.5-1.2-1.5-2.1-2.7-2.4l-0.6-0.2l-0.6-1.3c-0.6-1.3-1.8-2.1-3.2-2.3c-0.1,0-0.3,0-0.4,0
		c-1.3,0-2.4,0.6-3.2,1.6l-1.8,2.4c-0.6,0.7-0.8,1.6-0.8,2.6l0.1,2.4C17.8,324.3,19.5,326,21.5,326.1z"/>
            <path className="usbg" d="M144,383.3c0-0.1,0-0.2-0.1-0.3l-0.7-3.1c-0.3-1.3-1.2-2.3-2.4-2.8l-3.7-1.5c-0.1,0-0.1,0-0.2-0.1l-1.4-0.5
		l-0.2-0.5c-0.1-0.3-0.3-0.7-0.6-1l-1.4-1.8l-1.6-2.2l-2.2-3.9c-0.4-0.7-1-1.3-1.8-1.6l-1.2-0.6l-0.1-0.2c-0.3-0.7-0.7-1.2-1.3-1.6
		l0-0.2c-0.2-1-0.8-1.9-1.7-2.5l-2.5-1.7c-0.7-0.5-1.5-0.7-2.2-0.7c-0.8,0-1.7,0.3-2.4,0.8l-0.7,0.5c-0.8,0.2-1.6,0.6-2.2,1.2
		l-0.2,0.2l-0.8-0.8l0-0.6c-0.1-2.1-1.9-3.8-4-3.8h-1.6l0.2-51.9c0-1.3-0.7-2.6-1.8-3.3l-3.1-2.1c-0.2-0.2-0.5-0.3-0.8-0.4l-3.5-1.4
		c-0.5-0.2-1-0.3-1.5-0.3c-0.5,0-1.1,0.1-1.6,0.3l-0.8,0.3l-2-1c-0.6-0.3-1.2-0.4-1.7-0.4c-0.2,0-0.4,0-0.6,0l-2.1-1.4
		c-0.6-0.4-1.2-0.6-1.8-0.6l-2.3-0.2c-0.2-1.9-1.7-3.3-3.6-3.5l-2.1-0.2l-0.4-0.5c-0.2-0.2-0.4-0.4-0.6-0.6l-2.6-1.8
		c-0.7-0.5-1.5-0.7-2.3-0.7h-1.1c-1.1,0-2.2,0.5-3,1.3l-0.5,0.5c-0.5-0.2-1.1-0.4-1.6-0.4c-0.1,0-0.3,0-0.4,0l-1,0.1
		c-0.5,0.1-1,0.2-1.5,0.5l-1.8,1c-0.5-0.2-1.1-0.3-1.6-0.3c-0.8,0-1.6,0.2-2.2,0.7l-2.8,1.9c-0.7,0.5-1.2,1.1-1.5,1.9l-0.5,1.3
		l-0.5,0.4l-0.2,0l-0.4-0.1c-0.4-0.2-0.9-0.2-1.4-0.2h-2.4c-0.8,0-1.6,0.2-2.2,0.7l-2.4,1.6c-1.7,1.2-2.3,3.5-1.3,5.3l1.8,3.2
		c0,0.1,0.1,0.1,0.1,0.2l1.5,2.4l0,0.1l-2.9,0.5c-0.3,0-0.6,0.1-0.9,0.2h-4.8c-1.4,0-2.7,0.7-3.4,1.9l-0.6,1
		c-0.7,1.2-0.8,2.7-0.1,3.9l1.4,2.7c0.1,0.2,0.3,0.5,0.4,0.7l-0.3,2.4c-0.1,1.2,0.3,2.3,1,3.1c0,0,2.7,2.9,2.7,2.9l-1,0.5
		c-0.8,0.4-1.5,1-1.9,1.8l-0.8,1.5l-0.7,0.4c-0.1,0-0.1,0.1-0.2,0.1l-3.1,2.2c-0.8,0.6-1.4,1.5-1.6,2.5l-0.7,3.6
		c-0.1,0.4-0.1,0.8,0,1.2c-1-0.2-2.3-0.5-2.3-0.5c-0.3-0.1-0.6-0.1-0.9-0.1c-1,0-1.9,0.3-2.6,1c-1,0.8-1.5,2.1-1.4,3.3l0.2,2.6
		c0.1,0.9,0.5,1.8,1.1,2.5l2.5,2.6c0.7,0.7,1.7,1.2,2.7,1.2c0,0,2.7,0.1,2.8,0.1c0.3,0,0.5,0,0.8-0.1c0,0,0.1,0.1,0.1,0.1l1.2,1.1
		c0.6,0.5,1.3,0.9,2.1,1c0,0.4,0.1,0.7,0.2,1.1l-0.4,0.4c-1.1,1.1-1.4,2.7-1,4.1s1.7,2.5,3.2,2.7l2.9,0.4c0.2,0,0.4,0,0.5,0
		c0.6,0,1.1-0.1,1.7-0.4c0.1,0.1,0.2,0.1,0.4,0.2l0.2,0.1l0.1,0.6c0.2,0.8,0.6,1.5,1.2,2.1c-0.6,0.4-1.1,1-1.4,1.6l-1.8,0.4
		c-0.1,0-0.3,0.1-0.4,0.1l-2.2,0.7c-0.6,0.2-1.1,0.5-1.5,0.9l-1.9-0.1c-0.1,0-0.1,0-0.2,0c-0.6,0-1.2,0.1-1.8,0.4l-0.7,0.4l-1.5,0.3
		c-0.8,0.2-1.5,0.5-2,1.1l-0.8,0.8l-1,0.4l-3.3-0.3c-0.1,0-0.3,0-0.4,0c-0.5,0-1,0.1-1.5,0.3l-2.4,1c-1.2,0.5-2,1.5-2.3,2.7
		l-0.4-0.3c-0.7-0.7-1.7-1-2.7-1c-0.3,0-0.6,0-0.9,0.1l-1.8,0.4c-0.7,0.1-1.3,0.5-1.8,0.9L7.2,376c-0.2,0-0.3,0-0.5,0
		c-0.4,0-0.7,0-1.1,0.1l-2.2,0.6c-0.4,0.1-0.8,0.3-1.2,0.6L0,378.8c-1.1,0.8-1.8,2.1-1.7,3.5c0.1,1.4,0.8,2.6,2,3.3L1,386
		c0.6,0.3,1.3,0.5,2,0.5c0.2,0,0.5,0,0.7-0.1l1.1-0.2c0.2,0,0.4-0.1,0.6-0.2l1.6-0.6l1.1,0.4c0.2,0.1,0.4,0.1,0.6,0.2l1.8,0.3
		c0.2,0,0.4,0.1,0.7,0.1c0.3,0,0.6,0,0.9-0.1l2.9-0.7c1.1-0.3,2-0.9,2.5-1.9l0.5-0.9l1,0.7c0.7,0.5,1.5,0.7,2.3,0.7
		c0.8,0,1.5-0.2,2.2-0.6c0.2,0.1,0.4,0.1,0.6,0.2l1.7,0.3c0.2,0,0.5,0.1,0.7,0.1c0.6,0,1.2-0.1,1.8-0.4l1.2-0.6
		c0.1-0.1,0.2-0.1,0.3-0.2l0.3-0.2h1c0.2,0,0.4,0,0.6,0l0.1,0.1c0.1,0.3,0.2,0.5,0.4,0.8c0.7,1.2,2,1.9,3.4,1.9h2.7
		c1.6,0,3.1-1,3.7-2.5l0.6-1.4c0.1-0.1,0.2-0.2,0.3-0.3l0.2,0.1c0.5,0.2,0.9,0.2,1.4,0.2c1,0,2-0.4,2.8-1.1c0.1,0.7,0.4,1.4,0.9,1.9
		l1.7,2c0.8,0.9,1.9,1.4,3,1.4c0,0,0,0,0,0c1.2,0,2.3-0.5,3-1.4l1.2-1.4c0.8-1,1.1-2.3,0.9-3.5l1.3,0.3c0.3,0.1,0.7,0.1,1,0.1
		c0.7,0,1.4-0.2,2-0.5l3.3-1.9l2.9-1.7c1-0.6,1.6-1.5,1.9-2.6c0.2-0.8,0.1-1.5-0.2-2.3c0.5-0.5,0.8-1,1-1.7l0.3-1
		c0.1-0.5,0.2-0.9,0.2-1.4c0.2,0,0.4,0,0.4,0c0.7,0,1.5-0.2,2.1-0.6l2.4-1.5c0.3-0.2,0.7-0.5,0.9-0.8l0.2-0.3c0.1,0,0.2,0.1,0.3,0.1
		c0.5,0.4,1,0.7,1.6,0.9l1.4,0.4c0.4,0.1,0.7,0.2,1.1,0.2c1.1,0,2.2-0.5,3-1.3l0.7-0.7c0.5-0.1,1-0.3,1.5-0.5l1-0.6l3.2,1.9
		c0.6,0.4,1.3,0.6,2.1,0.6c0.6,0,1.2-0.1,1.7-0.4h1.3l2.9,1.3c0.1,0.1,0.3,0.1,0.4,0.2l2.4,0.7c0.2,0.3,0.5,0.5,0.8,0.7l3,1.9
		c0.1,0,0.1,0.1,0.2,0.1l2.5,1.4l1.1,1.3c0.2,0.2,0.4,0.4,0.7,0.6l1.1,0.8c0.1,1.2,0.8,2.3,1.8,2.9l0.4,0.3l0.2,0.3l0.4,2
		c0.3,1.5,1.4,2.7,2.9,3.1l1.3,2.2c0.2,0.4,0.5,0.7,0.8,1l1.9,1.7c0.2,0.2,0.4,0.3,0.5,0.4c0.1,0.5,0.3,1,0.6,1.4l0.1,0.2
		c0.4,0.7,1.3,1.8,2.4,2.5c0.1,0.1,0.2,0.1,0.3,0.1c0.4,0.6,0.8,1.4,1.1,1.7c0,0,0,0.1,0.1,0.1c0.2,0.2,1.4,1.6,3.1,2.1l0.2,0.1
		c0.4,0.1,0.8,0.2,1.3,0.2c0.7,0,1.4-0.2,2-0.5c0.4,0,0.7-0.1,1.1-0.3l1.4-0.6c0.3-0.1,0.5-0.3,0.8-0.4l0.1,0
		c0.6,0.3,1.2,0.4,1.8,0.4c1.2,0,2.4-0.5,3.2-1.5l1.4-1.8c0.3-0.4,0.5-0.8,0.7-1.2l0.8-2.5c0.2-0.6,0.2-1.2,0.2-1.8L144,383.3z"/>
          </g>






          <g className="location" id="utah" data-name="Utah" onClick={() => showLocation('utah')} style={{
            zIndex: 0
          }}>
            {/* <polygon id="Utah" points="169.1,207.8 114,200 127.6,126 158.3,131.7 157.4,138.7 155.8,147.4 161,148 171.8,149.2 177.2,149.8 "/> */}
            <g>
              <polygon className="st2" points="114,200 127.5,126 158.2,131.7 157.3,138.7 155.8,147.4 160.9,148 177.1,149.7 169.1,207.8 		" />
            </g>
            <g>
              <path className="st1" d="M127.9,126.6l29.8,5.5l-0.9,6.5l-1.4,8.2l-0.2,1l1.1,0.1l4.6,0.5l15.7,1.7l-7.9,57.1l-54.1-7.7L127.9,126.6
			 M127.1,125.4l-13.7,75l56.1,8l8.2-59.1l-16.7-1.8l-4.6-0.5l1.4-8.2l1-7.5L127.1,125.4L127.1,125.4z"/>
            </g>
          </g>

          <g className="location" id="arizona" data-name="Arizona" onClick={() => showLocation('arizona')} >
            <polygon className="st2" points="141.4,284.1 133.3,282.4 122.4,275.9 112.6,270.3 104.7,265.3 92.3,258.3 92,257.8 92.2,257.1 
			93.9,255.8 96.3,255.1 97.4,253.7 97.8,251.5 95.3,251 95,250.7 95.3,249.7 95.3,249.5 95.3,249.4 95.3,249.3 96.5,248.9 
			98.5,247 98.9,243.6 99.8,241.4 101.1,240.1 103.3,239 103.7,238.7 104.5,237.9 104.5,236.2 103.7,235.7 103.3,235.2 102.5,231.3 
			100.7,228.1 101.2,226 102.1,224.6 102.3,218.7 102.6,215 102.6,213.9 102.8,210.7 104.4,209.7 104.9,209.7 105.4,210.4 
			107.2,210.7 108.1,212.5 110.1,212.5 111.1,210.9 111.5,210.8 111.8,210.8 112.2,210.8 114.2,200.1 168.8,207.8 157.5,285.9 		"/>
            <path className="st1" d="M114.6,200.6l53.7,7.6L157,285.3l-15.6-1.7l-8-1.7l-10.9-6.5l-9.7-5.6l-8-5L92.7,258l-0.1-0.2l0.1-0.4
			l1.5-1.1l2.1-0.6l0.3-0.1l0.2-0.3l0.9-1.2l0.1-0.2l0-0.2l0.3-1.6l0.2-1l-1-0.2l-1.8-0.3l0.2-0.7l0-0.1v0l0.7-0.2l0.2-0.1l0.2-0.2
			l1.8-1.7l0.3-0.2l0-0.4l0.4-3.2l0.9-2.1l0.3-0.3l0.9-0.9l2.1-1l0.1-0.1l0.1-0.1l0.3-0.3l0.7-0.6l0.3-0.3v-0.5v-1.2V236l-0.4-0.3
			l-0.5-0.3l-0.3-0.4l-0.7-3.6l0-0.1l-0.1-0.1l-1.6-3l0.4-1.9l0.8-1.2l0.1-0.2l0-0.3l0.2-5.7l0.3-3.7l0,0v0v-1.1l0.2-2.9l1.3-0.8
			h0.1l0.3,0.4l0.2,0.3l0.4,0.1l1.3,0.2l0.3,0.5l0.4,0.8l0.3,0.6h0.6h0.7h0.7h0.6l0.3-0.5l0.8-1.2l0.1,0h0.3h0.8l0.2-0.8
			L114.6,200.6 M113.8,199.5l-2,10.8h-0.4l-0.6,0.1l-1,1.6h-0.7h-0.7l-0.4-0.8l-0.5-1l-1.8-0.3l-0.6-0.7h-0.8l-2,1.2l-0.2,3.5v1.1
			l-0.3,3.7l-0.2,5.7l-0.9,1.4l-0.5,2.4l1.8,3.3l0.8,3.9l0.6,0.7l0.6,0.4v1.2l-0.7,0.6l-0.3,0.3l-2.2,1.1l-1,1l-0.4,0.4l-1,2.4
			l-0.4,3.3l-1.8,1.7l-1.5,0.5l0.1,0.5v0.1l-0.3,1.2l0.5,0.7l2.2,0.4l-0.3,1.6l-0.9,1.2l-2.3,0.6l-2,1.5l-0.2,1.1l0.4,0.8l12.5,7
			l8,5l9.7,5.6l11,6.6l8.2,1.7l16.6,1.8l11.5-79L113.8,199.5L113.8,199.5z"/>
          </g>


          <g className="location" id="idaho"
            data-name="Idaho" onClick={() => showLocation('idaho')}
          >
            <path className="st2" d="M158.6,131.9c-1-0.2-57.8-11-62.5-12l5.8-23.2l0.9-2.8l1.7-3.9l-0.9-1.5l-1.6,0.1l-0.6-0.7l0.3-0.8l0.2-2
		l2.9-3.7l1.2-0.3l0.7-0.7l0.4-2.1l0.6-0.4l2.6-3.9l2.6-2.9l0.1-2.4l-2.3-1.8l-1-3l9-41.6l8.9,1.6l-2.9,14.1l2.4,5l-1,3.1l1.3,3.1
		l2.1,0.8l2.5,6.3l2.3,3l0.3,0.8l2.3,0.8l0.2,1.4l-4.6,11.4l-0.1,1.7l1.8,2.1h0.6l3.2-2l0.4-0.7l1,0.4l-0.2,3.5l1.9,8.2l2.6,2.2
		l1,1.4l-0.5,2.7l0.7,1.8l0.7,0.7l1.6-1.5l2,0.1l1.9,0.8l1.9-0.4l2.5-0.1l2.6,1l1.8-0.1l0.3-2l2-0.5l0.8,1L163,96l0.9,0.8
		L158.6,131.9z"/>
            <path className="st1" d="M119,23.2l7.9,1.4L124.1,38l-0.1,0.3l0.1,0.3l2.2,4.6l-0.9,2.7l-0.1,0.3l0.1,0.3l1.1,2.7l0.2,0.4l0.4,0.2
		l1.7,0.6l2.4,6l0.1,0.1l0.1,0.1l2.2,2.9l0.1,0.4l0.2,0.5l0.5,0.2l1.7,0.6l0.2,0.9l-4.5,11.2l-0.1,0.1l0,0.2l-0.1,1.4l0,0.4l0.3,0.3
		l1.5,1.8l0.3,0.4h0.5h0.2h0.3l0.2-0.2l3-1.9l0.2-0.1l0.1-0.2l0.2-0.3l0.2,0.1l-0.2,3.1l0,0.1l0,0.1l1.8,8l0.1,0.3l0.3,0.2l2.4,2
		l0.9,1.2l-0.4,2.4l0,0.3l0.1,0.3l0.6,1.6l0.1,0.2l0.2,0.2l0.3,0.3l0.7,0.7l0.7-0.7l1.1-1l1.7,0.1l1.6,0.7l0.3,0.1l0.3-0.1l1.6-0.4
		l2.4-0.1l2.4,0.9l0.2,0.1l0.2,0l1.3-0.1l0.8-0.1l0.1-0.8l0.2-1.3l1.4-0.4l0.5,0.6l0.3,1.6l0.1,0.4l0.3,0.2l0.6,0.5l-5.3,34.3
		c-5.5-1-54.5-10.4-61.4-11.8l5.6-22.7l0.9-2.7l1.6-3.7L105,90l-0.3-0.4l-0.6-1l-0.3-0.5l-0.6,0l-1.1,0.1l-0.2-0.2l0.2-0.5l0.1-0.1
		l0-0.1l0.2-1.8l2.7-3.4l0.9-0.2l0.3-0.1l0.2-0.2l0.5-0.5l0.2-0.2l0.1-0.3l0.3-1.7l0.4-0.3l0.2-0.1l0.1-0.2l2.5-3.7l2.5-2.7l0.2-0.3
		l0-0.4l0.1-2l0-0.5l-0.4-0.3l-2-1.5l-0.9-2.7L119,23.2 M118.2,22l-9.1,42.1v0.1l1.1,3.2l2.2,1.7l-0.1,2l-2.5,2.8l-2.5,3.8l-0.7,0.5
		l-0.4,2.1l-0.5,0.5l-1.2,0.3l-3.1,3.9l-0.2,2.1l-0.4,1l0.9,1.1l1.6-0.1l0.6,1l-1.6,3.7l-0.9,2.8l-5.9,23.7l0.5,0.1
		c4.6,1,61.9,11.9,62.5,12l0.5,0.1l5.5-35.9l-1-0.9l-0.3-1.9l-1.1-1.3l-2.6,0.7l-0.3,1.9l-1.3,0.1l-2.6-1l-2.7,0.1l-1.7,0.4
		l-1.8-0.8l-2.3-0.1l-1.4,1.3l-0.3-0.3l-0.6-1.6l0.5-2.8l-1.2-1.6l-2.5-2.1l-1.8-8l0.2-3.8l-1.7-0.7l-0.6,1l-3,1.9h-0.2l-1.5-1.8
		l0.1-1.4l4.6-11.5l-0.3-1.8l-2.3-0.8l-0.2-0.6l-2.3-3l-2.6-6.4l-2.1-0.8l-1.1-2.7l1-3.1l-2.4-5l3-14.4L118.2,22L118.2,22z"/>
          </g>



          <g className="location" id="nevada"
            data-name="Nevada" onClick={() => showLocation('nevada')} >
            <g>
              <polygon className="st2" points="99.4,225.1 88.2,208.1 88.4,207.9 54.4,156.7 66.8,112.9 127,126.3 117.4,178.9 117.9,178.4 
			111.9,210.4 111.5,210.4 110.9,210.4 109.8,212.1 108.5,212.1 107.6,210.3 105.8,210.1 105.3,209.4 104.4,209.4 103.7,209.8 
			102.3,210.5 102.2,215.8 102.1,216.4 101.7,224.6 100.8,225.7 		"/>
            </g>
            <g>
              <path className="st1" d="M67.1,113.5l59.3,13.2l-9.3,50.6l-0.6,3.2l0.5-0.5l-5.7,30h-0.3h-0.5l-0.3,0.5l-0.8,1.2h-0.4h-0.4l-0.2-0.4
			l-0.4-0.8l-0.2-0.5l-0.5-0.1l-1.4-0.2l-0.2-0.3l-0.3-0.4H105h-0.5h-0.3L104,209l-0.5,0.3l-1.2,0.6l-0.5,0.3l0,0.6l-0.1,4.9
			l-0.1,0.6l0,0l0,0l-0.4,8l-0.5,0.7l-0.9-0.4l-10.9-16.6L89,208l-0.5-0.7L55,156.6L67.1,113.5 M66.4,112.3l-12.5,44.5l33.8,51.1
			l-0.1,0.1l11.5,17.5l1.9,0.8l1.2-1.5l0.4-8.3l0.1-0.7l0.1-5l1.2-0.6l0.5-0.3h0.5l0.5,0.7l1.8,0.2l0.4,0.8l0.5,1h1h0.9l1.1-1.7h0.4
			l0.7-0.1l6.4-33.9l-0.6,0.6l9.5-51.6L66.4,112.3L66.4,112.3z"/>
            </g>
          </g>

          <g className="location" id="texas"
            data-name="Texas" onClick={() => showLocation('texas')} >
            <polygon className="st2" points="295.3,372.4 291.6,371.7 290.8,372 288.7,371.5 286,369.5 282.5,368.7 277.7,366.5 276.3,364 
			275.4,359.8 273.3,358.5 273,357.2 273.4,356.8 273.6,354.3 272.6,353.8 272.3,353.3 273.1,350.4 272,348.8 269.9,348 
			267.7,345.2 265.4,340.7 262.7,339.1 262.8,337.9 259.3,329.8 258.8,327 257.6,325.7 257.5,324.8 253.5,321.2 251.9,319.3 
			251.9,318.6 250,317 245.5,316.3 240.7,315.9 238.6,314.4 233.1,316.6 231.5,318.8 230.8,321.3 228.1,325.2 226.7,326.7 
			225.1,326.1 224,325.4 222.7,325 220.3,323.7 220.3,323.3 219,321.9 215.5,320.4 210.7,315.3 209.3,312.3 209.3,307.1 
			207.2,302.7 206.9,300.9 205.8,300.2 205.1,298.8 201.7,297.4 200.9,296.4 196.2,291.1 195.4,289 192.3,287.5 191.3,284.7 
			189.6,282.7 188.3,282.5 188,280 188.2,280 193,280.4 212.1,282.2 216.6,282.4 231.4,283.3 231.4,283.2 231.4,283.2 232.9,270.2 
			235.5,233.6 236.6,221.6 250.8,222.3 271.5,223.2 270,238.2 269.9,244 269.7,250.7 269.8,251.9 272.8,254.4 273.3,254.6 
			274.2,255.3 275.2,254.7 275.3,254.1 275.7,254.8 277.6,254.8 277.6,254.1 278.3,254.4 278.9,254.6 278.5,257.3 281.6,257.4 
			283.2,258.1 285.8,258.6 287.6,260 289.1,258.5 291.1,258.9 292.7,261.1 293.2,261.1 293.2,262.6 295,263.2 296.5,261.8 
			297.8,262.1 299.2,262.1 299.7,263.6 304.1,265 305.4,264.5 306.1,262.8 306.4,261.9 306.9,261.9 307.7,263.3 310.4,263.7 
			310.9,263.8 312.9,264.6 315,265.2 316.4,264.5 316.9,263 319.6,263 320.9,263.6 322.8,262.2 323.2,262.2 323.4,262.5 
			323.7,263.2 326.7,263.2 327.6,261.9 328.5,262.1 329.9,263.7 332.1,265 334.1,265.6 335,266.1 336.7,267.5 338.7,266.5 
			340.2,267.2 340.6,270.9 340.6,277.4 341.1,283.7 341.6,286.3 343.3,289.1 343.9,292.3 346.7,296 346.8,298.1 347.3,298.6 
			346.8,304 344.9,307.3 346,308.8 345.5,310.3 345,315.2 344.1,317.3 344.2,318.4 344.3,319.5 340.8,320.5 334.2,323.5 
			333.6,324.8 332,326 329.7,327.5 326,331 324.2,332.4 320.7,334.5 317,336 312.9,338.3 311.7,339.2 307.8,341.5 305.6,341.9 
			303,345.5 300.4,345.7 299.6,347.3 301.1,348.7 300.2,352.2 298.6,357.7 298.1,360.7 298.6,362.4 299.8,366.9 300.4,371 
			301.5,372.7 300.9,373.5 298.9,374.9"/>
            <path className="st1" d="M237,222.1l13.7,0.7l20.2,0.9l-1.5,14.5l0,0l0,0l-0.1,5.7l-0.2,6.7l0,0.1l0,0.1l0.1,0.9l0,0.4l0.3,0.3
			l2.8,2.4l0.1,0.1l0.1,0.1l0.3,0.2l0.6,0.5l0.5,0.4l0.6-0.3l0.5-0.3l0.1-0.1l0.1,0.1h0.6h1.1h1v-0.4l0.1,0l0.1,0l-0.2,1.7l-0.1,1.1
			l1.1,0l2.4,0.1l1.4,0.6l0.1,0l0.1,0l2.5,0.5l1.4,1.1l0.7,0.5l0.6-0.6l0.9-0.9l1.6,0.3l1.4,1.9l0.3,0.4h0.2v0.6v0.7l0.7,0.2
			l1.2,0.4l0.6,0.2l0.4-0.4l1.1-1l1,0.3l0.1,0h0.1h0.9l0.4,1l0.2,0.5l0.5,0.1l4,1.3l0.4,0.1l0.4-0.2l0.9-0.4l0.4-0.2l0.2-0.4
			l0.6-1.5l0.2-0.4l0.4,0.7l0.2,0.4l0.5,0.1l2.5,0.4l0.4,0.1l2,0.8l0,0l0,0l1.9,0.6l0.4,0.1l0.4-0.2l1-0.5l0.4-0.2l0.1-0.4l0.3-1
			h2.2l0.9,0.4l0.5,0.2l0.5-0.3l1.5-1.1l0.1,0.4l0.3,0.6h0.7h2.4h0.5l0.3-0.4l0.6-0.9l0.4,0.1l1.2,1.4l0.1,0.1l0.2,0.1l2.1,1.2
			l0.1,0.1l0.1,0l1.9,0.6l0.8,0.5l1.4,1.2l0.5,0.4l0.6-0.3l1.5-0.7l1,0.4l0.3,3.4v1.1v5.4v0l0,0l0.5,6.3l0,0.1l0,0.1l0.5,2.4l0,0.2
			l0.1,0.1l1.6,2.6l0.6,3l0,0.2l0.1,0.2l2.6,3.5l0.1,1.8l0,0.4l0.3,0.3l0.2,0.2l-0.4,5.1l-1.7,2.9l-0.3,0.6l0.4,0.5l0.7,1l-0.4,1.2
			l0,0.1l0,0.1l-0.5,4.7l-0.8,2l-0.1,0.2l0,0.3l0.1,0.9l0,0.8l-3.1,0.9l-0.1,0l-0.1,0l-6.3,2.9l-0.3,0.1l-0.2,0.3l-0.5,1l-1.5,1.1
			l-2.3,1.5l-0.1,0l-0.1,0.1l-3.7,3.5l-1.7,1.3l-3.4,2l-3.7,1.6l0,0l0,0l-4.1,2.2l-0.1,0l-0.1,0.1l-1,0.9l-3.8,2.3l-1.9,0.4
			l-0.4,0.1l-0.2,0.3l-2.3,3.2l-2.1,0.2l-0.6,0l-0.3,0.5l-0.5,1l-0.3,0.7l0.6,0.5l1,0.9l-0.8,3.2l-1.6,5.5l0,0.1l0,0.1l-0.5,2.9
			l0,0.2l0.1,0.2l0.5,1.5l1.2,4.5l0.6,3.9l0,0.2l0.1,0.2l0.9,1.4l-0.3,0.5l-1.7,1.1l-3.2-2.2l-0.2-0.1l-0.2,0l-3.5-0.7l-0.3-0.1
			l-0.3,0.1l-0.5,0.2l-1.8-0.4l-2.5-1.9l-0.2-0.1l-0.2,0l-3.3-0.8l-4.7-2.1l-1.3-2.3l-0.8-3.9l-0.1-0.4l-0.4-0.2l-1.8-1.1l-0.2-0.8
			l0.1-0.1l0.3-0.3l0-0.4l0.2-2l0.1-0.7l-0.6-0.3l-0.5-0.3l-0.1-0.2l0.7-2.5l0.1-0.5l-0.3-0.4l-0.9-1.3l-0.2-0.3l-0.3-0.1l-1.8-0.7
			l-2.1-2.6l-2.3-4.3l-0.1-0.2l-0.2-0.1l-2.3-1.4l0.1-0.8l0-0.2l-0.1-0.2l-3.5-8l-0.5-2.6l-0.1-0.3l-0.2-0.2l-1-1.1l-0.1-0.6l0-0.4
			l-0.3-0.3l-3.8-3.4l-1.5-1.8v-0.3v-0.5L252,318l-1.6-1.3l-0.2-0.2l-0.3,0l-4.4-0.7l0,0l0,0l-4.6-0.4l-1.8-1.2l-0.4-0.3l-0.5,0.2
			l-5.1,2l-0.3,0.1l-0.2,0.2l-1.4,2l-0.1,0.1l0,0.2l-0.7,2.3l-2.7,3.8l-1.2,1.3l-1.2-0.4l-1-0.6l-0.1-0.1l-0.1,0l-1.2-0.4l-2.1-1.2
			v-0.3l-0.3-0.3l-1.1-1.2l-0.1-0.2l-0.2-0.1l-3.3-1.4l-4.6-4.9l-1.3-2.9v-5V307l-0.1-0.2l-2-4.2l-0.3-1.6l-0.1-0.4l-0.4-0.2
			l-0.8-0.5l-0.6-1.1l-0.2-0.3l-0.3-0.1L202,297l-0.8-0.9l-4.6-5.2l-0.7-1.8l-0.1-0.4l-0.3-0.2l-2.7-1.3l-0.9-2.5l-0.1-0.2l-0.1-0.1
			l-1.6-1.8l-0.2-0.2l-0.3-0.1l-0.7-0.2l-0.2-1.5l4.3,0.4l19.1,1.8l4.5,0.2l14.3,0.9l1.1,0.1v-0.2l0.1-0.8l1.4-12.6l2.6-36.5
			L237,222.1 M236.1,221.1l-1.1,12.5l-2.6,36.5l-1.4,12.6h-0.1v0.1l-14.3-0.9l-4.5-0.2l-19.1-1.8l-4.7-0.4l-0.9-0.1l0.5,3.5l1.4,0.3
			l1.6,1.8l1,2.9l3.1,1.5l0.8,2l4.7,5.3l0.9,1.1l3.3,1.4l0.7,1.4l1,0.6l0.3,1.7l2.1,4.3v5.2l1.5,3.2l4.9,5.2l3.5,1.5l1.1,1.2v0.5
			l2.7,1.5l1.3,0.4l1.1,0.7l1.9,0.7l1.7-1.8l2.8-4l0.7-2.5l1.4-2l5.1-2l2,1.4l4.9,0.4l4.4,0.7l1.6,1.3v0.7l1.8,2.1l3.8,3.4l0.1,0.9
			l1.2,1.3l0.5,2.7l3.5,8.1l-0.1,1.4l2.8,1.7l2.3,4.4l2.3,2.9l2.1,0.8l0.9,1.3l-0.8,2.9l0.5,0.8l0.8,0.4l-0.2,2l-0.5,0.5l0.5,1.7
			l2.1,1.3l0.8,4.1l1.5,2.7l5.1,2.3l3.4,0.8l2.7,2l2.3,0.5l0.8-0.3l3.5,0.7l3.8,2.6l2.4-1.6l0.8-1.2l-1.2-1.9l-0.6-4l-1.2-4.6
			l-0.5-1.5l0.5-2.9l1.6-5.5l1-3.8l-1.5-1.3l0.5-1l2.6-0.2l2.6-3.6l2.1-0.4l4-2.4l1.1-0.9l4.1-2.2l3.8-1.6l3.5-2.1l1.8-1.4l3.7-3.5
			l2.3-1.5l1.7-1.3l0.6-1.2l6.3-2.9l3.9-1.1l-0.1-1.6l-0.1-0.9l0.9-2.1l0.5-4.9l0.5-1.7l-1-1.4l1.8-3.1l0.5-5.8l-0.5-0.5l-0.1-2.1
			l-2.8-3.7l-0.6-3.2l-1.7-2.8l-0.5-2.4l-0.5-6.3V272v-1.1l-0.4-4.1l-2-0.8l-1.9,0.9l-1.5-1.2l-1-0.6l-2-0.6l-2.1-1.2l-1.3-1.6
			l-0.1-0.1l-1.4-0.3l-1,1.4H324l-0.2-0.5l-0.3-0.5h-0.9l-1.8,1.3l-1.1-0.5h-3.2l-0.5,1.7l-1,0.5l-1.9-0.6l-2.1-0.8l-0.5-0.1
			l-2.5-0.4l-0.8-1.4h-1.1l-0.5,1.2l-0.6,1.5l-0.9,0.4l-4-1.3l-0.6-1.6h-1.6l-1.5-0.4l-1.5,1.4l-1.2-0.4v-1.6H293l-1.6-2.2l-2.5-0.4
			l-1.3,1.3l-1.6-1.2l-2.7-0.5l-1.6-0.7l-2.6-0.1l0.3-2.5l-0.9-0.3l-1.4-0.8v1.1H276l-1-1.6l-0.3,1.7l-0.5,0.3l-0.7-0.5l-0.4-0.2
			l-2.8-2.4l-0.1-0.9l0.2-6.7l0.1-5.7l1.6-15.6l-21.3-0.9L236.1,221.1L236.1,221.1z"/>
          </g>

          <g id="Unused" >
            <path id="Maryland" className="st0" d="M545.3,177.3l-0.7-0.3l-2.2-2.9l-1.2,0.6l-2.4-0.3l-1.6-2.1l0.2-2l1.2-1l-1.5-1.6l-0.8,1.4
    l-0.2-1.8l1.4-0.6l-0.4-4l-1.2-0.2l-0.1-0.5l0.8-2.3l1-1.2l-0.5-1.1l-1.5,1.3l-1.3,0.9v0.1c0,0-0.1,0.2-0.2,0.5
    c-0.1,0.3-0.2,0.6-0.2,0.9c0,0,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.5-0.5,0.7c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.1-0.1,0.1
    l-0.2,0.2l1,1l0.1,1.3l-0.9,3.5l1.4,3.7l1.6,1.3v1.1l0.9,1.3l-0.5,1.1l-1.2-0.2l-2-0.6h-0.9h-2l-1.7-0.4l-1.3-0.4l-0.2-0.6
    l-0.1-0.4v-0.1v-1.1l0.7-1.1l0.5-0.6l0.4-2.4l0.1-0.1l0.9-1.9l-2.2-1.5h-0.1l-0.9,1.3l-0.4-0.2l-1.6-0.4l-2-0.5v-0.9v-1l-0.9-0.4
    l-1.4-0.6v-0.1l-1-0.9l-1.3-1.2l-2.4-1.2l0,0l-0.9-0.7l-1.4,0.4l-1.4,1l-1.7,1.3l-1.8,0.2l-1.1-0.5l-1.3,1.9l-0.9,0.9l-1.7,0.4
    l-1,1.4l-0.4,0.5l-1.2,1.4l-0.6-3.3l-0.7-3l24.4-4.9l9.8-1.9l1.7-0.4l3-0.6l0.3,1l0.5,1.7l1.4,3.5l0.3,0.7l1.3,6l0.8,3l0.5,1.8
    l3-0.1h0.6l2.6-0.5l1.1-0.2l0.3,2.9l-1.2,2.5l-3,1.3L545.3,177.3 M541.9,179.4l-0.5-0.9l-0.1-1.2l-0.6-1.4l0.7-0.2l0.4,1.3
    c0,0.1,0.1,0.5,0.2,0.9c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0,0.2,0.1,0.2v0.1l0,0c0,0,0,0.1-0.1,0.1C542,179.3,542,179.4,541.9,179.4"
            />
            <polygon id="Deleware" className="st0" points="543.6,170.5 543.2,169.1 542.6,166.6 541.2,160.2 541.2,160.1 541.2,160.1 540.9,159.4 
    539.7,156.4 539,154.4 538.7,153.3 539.9,152.3 540.5,152.1 540.9,152 541.6,151.8 541.5,152.5 541.5,152.5 541.5,152.5 
    541.4,152.5 541.4,152.6 541.3,153.3 540.9,155.1 541.3,155.9 541.6,156.6 541.7,156.7 541.7,156.7 541.7,156.7 543.6,158.5 
    544.9,160.8 546.1,163.9 548.6,165.6 549.1,165.4 550.4,169.7 549.3,169.9 546.7,170.4 546.2,170.4 	"/>
            <path id="Virginia_-_North" className="st0" d="M526.4,173l0.9-1.2l0.4-0.5l0.4-2.3l-0.8-0.9l-0.3-0.3l-0.9-0.5l-0.6-0.3l-1.4-0.3
    l-2.4-0.5l0,0l0,0v-1.3v-0.6l-0.6-0.3l-1.3-0.5l-0.1,0.9c0,0.6-0.1,1.3-0.2,1.8l-0.1,0.8l-3.6-1.6l-3-1.2l-0.1,3l0,0l0,0l-1.2,2.4
    l-0.7,1.4l15.5,2.7L526.4,173z"/>
            <polygon id="Washington_DC" className="st0" points="528.3,168.4 527.7,167.8 527.4,167.4 526.8,167.1 527.4,166.2 528.9,167.2 	" />
            <path id="Pennsylvania" className="st0" d="M536.9,153.3l5-1.5l2.5-2l1.2-2.2l2.5-2.5l-4.3-3l-0.6-1.4l-2.4-0.5l-0.1-0.4l-0.5-2v-0.1
    l1.5-0.8l0.1-1.2l-0.9-0.9l0.2-1.2v-0.1l1.2-2v-2h0.1v-0.1l0.2-0.2l1.3-1.5l-0.2-0.1h-0.4l-1.3-0.1l-0.5-0.5l-1.1-0.9l-0.6-2.4
    l-0.4-1.5l-2.2,0.1l-1.6-1.8l-0.5,0.1l-11.3,2.6l-28.3,5.8l-6.1,1l-0.3-3.8l-0.1-0.3l-3.2,3l-0.8,0.4l-2.7,1.8l1.9,12.5l1.5,5.6
    l0.1,0.5l0.1,0.3l2.3,12.4l1.9-0.4l3.2-0.4l5.7-0.8h0.2h0.1l23.6-4.8l9.8-1.9l2.5-0.5l1.5-0.3H536.9L536.9,153.3"/>
            <polygon id="Jersey_South" className="st0" points="556,147.8 555.9,141.6 554.9,139.7 554.4,140.1 552.4,140.3 551.8,139.7 
    552.7,138.9 554.1,137.7 554.1,137.4 554.1,137.2 541.8,137.5 541.1,137.8 540.8,138 541.2,139.6 541.3,140.2 543.1,140.4 
    543.8,142.1 546,143.7 547.7,144.8 547.7,145 547.7,145.6 547.7,145.7 546.7,146.6 545.6,147.6 544.7,148.9 544,150.3 543.7,150.8 
    543,151.2 542.2,151.6 541.9,152.6 541.9,152.7 541.7,154 541.5,155 541.7,155.7 542.1,156.4 544.1,158.2 547.2,159.7 550.1,160.1 
    550.2,161.4 549.7,162 549.8,163.5 550,163.5 551.3,162 551.8,158.9 553.7,156.2 555.7,152 556.4,148.5 	"/>
            <path id="NewYork_1_" className="st0" d="M545.5,128.1c-0.8-0.3-0.1,0-0.8-0.3l0,0l-0.4-0.3l-0.4-0.3h-0.6l-1.1-0.1l-0.4-0.4l-0.9-0.8
    l-0.6-2.3l-0.5-1.9l-2.4,0.1l-1.6-1.8l0,0l0,0l-0.8,0.2l-12.1,2.7l-28.3,5.7l-4.7,0.8l-0.4-3.3l-0.1-0.6l0.8-0.6l0.9-0.8l0.6-1
    l1.1-0.7l1.3-1.2l0.3-1.1l1.3-1.8l0.8-0.7l-0.1-0.8l-0.9-2.2l-1.2-0.1l-1.2-3.7l1.7-1.1l2.8-0.9l2.6-0.8l2.1-0.3l4.1-0.1l1.3,0.8
    l1.2,0.1l1.4-0.9l1.6-0.7l3.4-0.3l1.5-1.2l1.2-2.1l1-1.2h1.3l1.4-0.8l0.1-1.7l-1-1.4l-0.2-0.8l0.7-1.4v-1.3h-1.4l-1.1-0.5l-0.5-0.6
    l-0.1-1.5l3.8-3.5l0.5-0.6l1-1.9l1.9-3l1.8-2.4l1.4-1.6l1.5-1.2l2-0.8l3.6-0.8l2.1,0.1l3-1l4.5-1.2l0.5,3l1.6,4.3l0.5,3.3l-0.7,2.6
    l1.8,3.1l0.5,1.2l-0.6,2l0,0v0.1l2.1,0.9l0.3,0.2l1.9,6.9l-0.1,2.3l-0.1,1.1v0.1l0,0l-0.4,7.2l0.4,3l0,0v0.1l0.1,0.4h0.1l0.4,1.3
    c0.1,0.4,0.5,2.4,0.6,2.8C551.1,123,548.9,125.1,545.5,128.1z"/>

            <polygon id="Jersey_North" className="st0" points="541.9,137.2 553.8,136.8 553.8,134.8 554.2,133 554.1,131.9 552.4,130.8 
    550.9,130.5 549.5,130.2 546.9,129.4 545.7,128.8 544.3,128.2 543,129.5 542.8,129.7 542.8,131.6 542.8,131.7 541.6,133.7 
    541.5,134.6 542.3,135.4 	"/>
            <polyline id="NewYork" className="st0" points="546.6,128.7 545.9,128.4 554.8,120.4 555.2,122.6 555.3,126.1 555.3,128.1 554.8,129.1 
    554.4,129.7 554.5,129.8 555.8,131.1 556.2,132 554.9,133.2 555.3,134.4 556.4,134.1 557.4,133.2 558.9,131.6 559.5,131.2 
    560.5,131.6 562.1,131.7 567.4,129.1 569.3,127.3 570.1,126.4 572.3,127.3 570.4,129.4 567.8,131.3 563.2,134.8 561.5,135.4 
    557.7,136.7 555.1,137.4 554.6,137.1 554.6,137.1 554.6,137.1 554.6,136.9 554.4,134.9 554.7,133.1 554.6,131.5 552.6,130.3 
    551,130 549.3,129.6 546.6,128.7 	"/>
            <polygon id="New_Hampshire" className="st0" points="561.7,103.4 561.3,103.1 561.5,102.5 561.6,102.2 561.6,102.1 561.6,102.1 
    561.7,99 561.1,98.2 561.1,97.6 561.2,94.7 561.7,89 561.7,86.1 561.8,83.1 561,82.1 561,81.6 561.6,81.4 562.6,80.9 565.1,77.5 
    565.1,74.7 564.5,73.8 563.8,72.8 563.6,69.1 563.7,66 564.4,65 564.8,65.4 564.8,65.4 565.3,65.8 565.5,66 566,64.8 566.2,64.8 
    567.7,69.2 569.2,74.1 574,89.5 574.2,91.5 574.3,91.5 574.3,91.6 576.8,92.3 577.1,94.2 577.2,94.3 577.2,94.3 578.6,95 
    578.1,96.7 577.6,97.3 575.2,98.4 575.2,98.4 575.2,98.4 574.2,99.9 574,100.2 572.6,100.6 	"/>
            <polygon id="Vermont" className="st0" points="554.1,104.9 554.2,104 554.4,101.7 552.3,94.3 551.8,94 551.8,94 550.1,93.3 550.6,91.6 
    550.6,91.5 550.6,91.5 550,90 548.4,87.1 549,84.6 548.4,81.1 546.8,76.9 546.5,73.8 563.2,69.6 563.3,73 564.1,74.1 564.6,74.8 
    564.6,77.3 562.3,80.5 561.4,80.9 560.5,81.3 560.5,81.5 560.5,82.3 560.5,82.4 561.2,83.4 561.2,86.1 561.1,88.5 560.7,94.6 
    560.7,94.7 560.7,95.1 560.6,97.5 560.6,98.3 561.2,99.2 561.1,102.1 561,102.4 560.7,103.3 560.8,103.3 560.8,103.4 561.1,103.6 
    558.7,104.1 556.5,104.5 556.2,104.6 	"/>
            <path id="Maine" className="st0" d="M578.9,94.3l-1.3-0.5l-0.3-2l0,0v-0.1l-2.5-0.7l-0.2-1.6L569.8,74l-1.6-5.1l-1.3-4.2h0.6l1.3,0.3
    v-2l0.8-2.9l1.7-3.1l1-2.8l-1.3-1.6v-3.8l0.5-0.6l0.6-1.9l-0.1-1l-0.1-3.1l1.2-3.1l1.9-5.8l1.3-2.6h0.7l0.6,0.1v0.5v0.1l0.9,1.6
    l2,0.5l0.7-0.7v-0.6l2.6-1.9l1.1-1.1l0.8,0.1l3.9,1.6l1.2,0.6l6,19.7h4l0.5,1.1l0.1,3.3l2.1,1.6h0.6h0.2l0.2-0.5v-0.1l-0.2-0.5
    l1.3-0.1l1.2,1.3l1.4,2.4v1.2l-1.3,2.9l-1.2,0.4l-2.3,2.1l-3.1,3.5h-0.7l0,0c0,0-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.2-0.6
    c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.1,0-0.1v-0.2l-1.5,0.1l-0.7,1l-1.6,1l-0.8,1.2l1.1,0.9l-0.2,0.2l0,0l-0.3,1.6l-0.8-0.1v-0.8l0,0
    l-0.3-1.1l-1,0.2l-1.2-2.2l-1.9,1.1l1,1.1l0.2,0.6l-0.5,0.8l0.2,2.1l0,0l0.1,1l-1,1.5l-2,0.3l-0.2,2l-3.8,2l-0.7,0.3l-1.1-1
    l-2.3,2.6l0.6,2.1l-0.9,0.8l-0.1,3L578.9,94.3"/>
            <polygon id="Rhode_Island" className="st0" points="573.5,121.2 571.2,111.9 574.5,110.9 574.9,110.7 576.3,111.9 578.4,114.7 
    580,117.4 578.4,118.3 577.6,118.2 577.4,118.2 576.6,119.4 575.1,120.7 	"/>
            <polygon id="Connecticut" className="st0" points="556,130.6 555.1,129.6 555.2,129.4 555.8,128.3 555.8,126.1 555.8,122.9 
    555.7,122.5 555.7,122.3 554.7,117.5 554.5,116.9 554.3,115.7 554.2,115.6 567.8,112.6 570.7,112 573,121.4 568.1,123.4 
    564.7,124.1 563,125 562.6,124.7 562.4,124.5 561,126.1 558.6,128.2 	"/>
            <path id="Massachussets" className="st0" d="M580.5,117.2l-1.7-2.8l-2.3-2.9l-1.5-1.3l0,0l0,0l-0.7,0.2l-3.7,1.1l-3.3,0.7l-13.2,2.9
    l-0.4-2.9l0.3-6.6l2.2-0.5l0.6-0.1l2-0.4l2.3-0.4l0,0l0.4-0.1l0,0l0,0h0.1l0.5-0.1l0,0l10.5-2.8l1.5-0.4l0.3-0.5l1-1.4l2.2-1
    l1.7,2.6l-1.5,3.3l-0.2,1.1l1.5,2l0.9-0.6h1l1.4,1.6l2.6,4l2.5,0.3l1.6-0.7l1.3-1.3l-0.6-2l-1.6-1.2l-0.9,0.5l-0.4-0.5l0.1-0.1
    l1.2-0.1l1.1,0.5l1.2,1.5l0.6,1.9l0.2,1.4l-2.6,0.9l-2.6,1.3l-1.7,1.9l0.2-0.6l-0.6-2.4l-2.3,1.1l-0.6,1.1l0.3,1.4L580.5,117.2z
      M582.8,117.7L582.8,117.7L582.8,117.7L582.8,117.7 M590.6,117.9l1.1-0.4l0.3-1.1h0.3l0.5,1.1l-0.5,0.2L590.6,117.9 M584.7,118.6
    l-0.4-0.3l1.3-1.5h0.9l0.8,0.7L586,118L584.7,118.6"/>

            <path id="Louisiana" className="st0" d="M385.6,327.9l-5.4-2.3l-0.8-0.8l0.6-0.4l0.6-1.3l-1.4-1.4l-2.9-0.2l-0.2-1.3l-1-1.7l-2.3-0.5
    l-1.1,0.6l-1.1-0.9h-0.1c0,0-0.4,0.1-0.8,0.3c-0.4,0.1-1,0.3-1.2,0.4c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2-0.2,0.4-0.3,0.6
    c0,0.2-0.1,0.3-0.1,0.3v0.2l2,1.2l1.7-0.3l1.8,0.6l-0.4,0.9l-1.9-0.6l-2.2-0.8l-2.5,1l-4.2-0.7l-6.8-2.3l-8-0.1l-2.8,0.9l-0.1-1v-1
    l0.9-2.1l0.4-4.9l0.4-1.6l0,0v-0.1l-0.7-1l-0.2-0.3l1.4-2.4l0.5-0.9l0.3-4.3l0.1-1.3l0,0v-0.1l-0.5-0.5l-0.1-2.1l-1.4-1.8l-1.4-1.8
    l-0.6-3.2l-1.8-2.9l-0.4-2.4l-0.4-6.1v-5.5v-0.4l36-0.7l0.1,4l0.7,1.6l0.5,1.1l0.1,1.4l1.5,1.3l-0.2,1l-0.9,0.5l0.2,0.7l0.2,0.9
    l-3.4,5.9l0,0l-0.6,1.1l-1.4,2.5l0,0v0.1l-0.6,5.6l0.3,0.9l0.2,0.8l9.4-0.2l7-0.1h0.2h0.1l6-0.6l0.3,0.4l-0.3,1.8l-0.4,1.8l1.6,1.5
    l0.7,0.6l0.8,2.6l0.7,1.5l-1.2,2.1l-1.3,1.4l0.8,1.4l2.4,0.5l0.5-1.9l1.2-1.3l0.6-0.1l0.2,1.6l0.8,1.2l-1,1.8h-2.2v0.6v0.1l1.1,0.6
    l0,0l-1.5-0.2l-0.9,2.2l2.3,1.6l3.8,0.5l2.2,1.8h0.7v0.6l-1.3,1.7l-0.6-0.5h-1.9l-0.6-0.9l-2.7-1.3l0,0c0,0-0.6-0.1-1.2-0.2
    s-1.3-0.2-1.6-0.2c0,0,0-0.1-0.1-0.1c-0.1-0.3-0.1-0.9-0.1-1.2c0-0.2,0-0.4,0-0.4v-0.1l-0.7-0.8l-0.9,1l-1.5,0.4l0.1,1.1l-0.6,1.3
    l1,0.2l-1.5,2.2l-2,0.8l0,0l1.3-0.5l-0.6-2.2l-1.1-0.8l-1.3,1l-1.8,0.4l-0.6,1.6L385.6,327.9"/>
            <polygon id="Oklahoma" className="st0" points="334.9,265.7 334.3,265.3 332.2,264.7 330.1,263.5 329.5,262.7 328.8,261.9 328.6,261.8 
    327.3,261.5 327.3,261.5 327.3,261.5 327.2,261.6 326.2,262.9 323.9,262.9 323.6,262.4 323.4,261.8 323,261.8 322.4,261.8 
    320.6,263.1 320.2,263 319.5,262.6 316.3,262.6 316.3,262.6 316.2,262.6 315.8,264.3 315.4,264.5 314.8,264.8 314.7,264.8 
    312.6,264.1 310.8,263.5 310.5,263.3 309.8,263.2 307.9,262.9 307.2,261.7 307.1,261.5 307,261.5 306.4,261.5 306.4,261.5 
    306.1,261.5 306,261.5 305.5,262.7 304.8,264.3 304.1,264.7 300.1,263.4 299.5,261.7 297.8,261.7 297.7,261.7 296.4,261.2 
    295.8,261.8 294.9,262.6 293.7,262.2 293.7,262.2 293.8,260.9 293.7,260.9 293.7,260.8 293.4,260.8 293,260.7 291.6,258.5 
    290.5,258.4 288.9,258 287.5,259.3 286.1,258.1 283.5,257.8 281.5,257 280.4,256.9 279.1,256.9 279.2,255.8 279.3,254.4 
    279.3,254.4 279.3,254.4 279.3,254.4 277,253.2 277,253.3 276.9,253.2 276.9,254.3 276,254.3 275.3,253.4 274.9,252.7 274.9,252.9 
    274.4,254.5 274.1,254.6 273.3,254.1 272.9,253.8 270.1,251.4 270.1,250.1 270.2,243.9 270.3,238.9 271.8,222.6 271.8,222.6 
    271.8,222.5 251.1,221.7 247.4,221.6 237.3,221 237.8,214.3 241.1,214.5 248.6,215.2 248.6,215.1 249.7,215.2 264.5,216 
    294.2,217.4 324.7,217.7 332.3,217.5 332.4,220.6 332.8,224.3 332.9,224.3 333.9,228.6 333.9,234 334.8,241.2 "/>

            <polygon id="Kansas" className="st0" points="324.2,217.1 293.9,216.8 264.6,215.4 264.6,215.4 249.7,214.7 248.6,214.6 249.2,204.3 
    251.1,172.6 265.4,173.1 291.9,173.6 321.1,174.3 324.2,174.3 325,175 325.8,175.7 326.3,175.7 327.2,175.8 328,176.3 328,178 
    327.1,178.8 326.8,179 326.8,179 326.8,179.1 326.7,179.9 326.5,180.7 326.7,181.1 327.8,183.1 329.2,184.6 329.6,185 329.7,185.1 
    329.8,185.2 330.6,185.8 331.2,186.2 332.1,193.7 332.1,196.2 332.2,217 "/>
            <polygon id="Missouri" className="st0" points="386.4,229.7 384.7,229.5 383.3,229.3 383.5,228.6 384.5,227.8 385.6,226.9 385.9,225.1 
    386.1,224.4 384.8,222.2 384.5,222.2 384.5,222.1 376.7,222.3 363,222.8 363,222.8 347.7,223.3 333.4,223.8 333,219.7 332.9,217 
    332.8,217 332.6,196.2 332.6,193.5 331.7,185.9 330.9,185.4 330,184.8 329.6,184.3 328.3,182.8 327.1,180.8 327.1,180.6 327.1,180 
    327.3,179.3 327.4,179.2 328.5,178.2 328.5,176 328.5,176 328.5,176 327.2,175.2 326.3,175.2 326,175.2 325.4,174.6 324.5,173.8 
    324.4,173.8 324.3,173.6 323.6,172.9 322,170.7 321.8,170.4 320.4,168.2 320.4,167.8 320.6,166.8 320.2,166.1 319.9,165.4 
    320,165.4 321,165.3 363.2,163.8 363.8,165.1 364.8,166.3 365.4,167.1 364.9,168.5 364.9,168.5 364.7,169.1 365,171.6 366.6,176.5 
    369.8,179.8 371.3,181.4 372.7,182.4 374.8,183.7 374.9,185.7 374.8,186.6 375.5,187.7 377.1,187.8 380.1,188.2 381.3,188.8 
    381,190.4 379.8,194 379.5,194.9 379,197.1 380.2,199.2 380.3,199.4 380.4,199.5 380.6,199.8 384.9,203.3 387.8,203.8 389.1,207 
    390.4,209 390,210.4 390,210.5 389.8,210.9 390.7,213.3 390.8,213.7 392,215 392.3,215.3 392.3,215.3 392.3,215.4 393,214.9 
    393.2,214.7 394.1,216.1 394,216.3 393.5,217.3 393.4,218.7 393.4,219.2 392.4,219.7 391.8,220.1 391.4,220.6 391.1,220.9 
    390.9,220.9 390.9,221.2 390.6,221.4 390.7,221.4 390.3,221.9 389.9,225.8 388.8,229.2 "/>
            <polygon id="Arkansas" className="st0" points="341.1,271.2 340.8,268.5 340.7,267.1 338.7,266.2 338.7,266.2 338.6,266.2 337.5,266.7 
    336.7,267.1 336.1,266.5 335.7,266.2 335.4,241.8 334.5,234.6 334.4,228.7 333.5,224.4 333.5,224.3 384.1,222.7 385.4,224.7 
    385.3,225 384.9,226.7 384,227.4 382.9,228.2 382.4,229.8 384.5,230 386.2,230.2 388,229.9 388.5,229.8 386.8,235.5 384.9,237.1 
    385.7,239.9 387.6,240.2 386.5,240.9 386.4,242.4 386.4,242.9 386.4,243 385,243.9 383.1,247.7 383.3,248.9 383.4,249.8 
    382.1,250.4 381.6,250.6 379.1,254.2 379.1,254.3 379.1,254.3 379.3,254.9 379.5,255.7 378,257 377,260.9 376.6,262.6 376.6,262.6 
    376.6,262.6 377.6,264.2 376.5,265.1 377,265.9 377.8,267.3 377.9,269.4 377.3,270.6 "/>

            <path id="Florida" className="st0" d="M515.8,370.6l-0.7-0.3l-0.6-0.5l-0.2-1.8l-0.5-2.3l-2.3-3.5l-2.4-1.6l-2.8-0.2l-0.4,0.7l-1.8-2.5
    l-0.4-2.3l-1.8-2.7l-1.4-0.9l-1.1,1.4l-0.9-0.2l-1.3-3.2l-1.9-2.6l-1.9-3.5l-1.7-2l-2.2-2.3l1.3-1.4l2.2-3.7l-0.1-1.3l-3.2-0.7
    l-1.4,0.6l0.4,0.8l1.6,0.6l-0.9,2.7l-0.2,0.1l-1.1-2.4l-0.8-3.2l-0.2-1.7l1-3.1V321l-2.1-2.5l-0.9-2.1l-3.5-0.9l-1.2-0.4l-1.1-1.7
    l-2.2-1l-0.7-2.2l-1.8-0.6l-1.6-2.4l-2.8-1l-2-1h-2.1l-2.9,0.6l-0.1,1.6l0.5,0.6l-0.2,0.4l-2-0.1l-2.5,2.4l-2.3,1.2h-2.5l-1.9,0.7
    l-0.1-1.6l-1.2-1.4l-1.9-0.8l-1.1-1l-5.4-2.6l-5.1-1.2l-2.9,0.4l-4,0.3l-3.9,1.4l-1.6,0.3l-0.4-5.1l-1.8-1.3l-1.1-1.1l0.1-1.6
    l6.6-0.9l16.8-1.9l4.4-0.4l3.2,0.2l1.6,2.4l1,1h0.1l0.5,0.4l5.3,0.1l7.1-0.4l14.2-0.9l3.6-0.4h2.7v0.2l0.1,1.6l2.2,0.7l0.2-3.2
    l-1-2.9l0.4-0.6l0.2-0.2l1.3,0.1l2.4,0.3l3.1,0.2l1.4,4.5l2.5,6.4l3.5,6.2l2.5,4.2l3.2,3.6l2.6,2.4l1,1.7l-0.6,0.7l-0.6,1l2,5.1
    l1.9,1.9l1.7,3.5l2.3,3.8l3,5.4l0.8,4.9l0.3,7.9l0.4,1.2l-0.2,2l-1.6,0.9l0.2,1.4l-0.4,1.3l0.2,1.7l0.3,1.2l-1.7,2l-1.9,0.9
    l-2.6,0.1l-1,1.1L515.8,370.6z M519.6,374.2l2.7-2.6l2.2-3.5l1-3.1l0.6-1.8l0,0l-0.1,1.7l-0.6,1.1l-1.6,4.3l-2.4,2.5L519.6,374.2
      M516.2,376.8l-0.4-0.3l1.4-0.8l2.1-1.2l-1.4,1.1L516.2,376.8 M509.3,379.1l0.8-1.2l1.4-1l0.7,0.3l1,0.2l0.1,0.3l-2,0.7
    L509.3,379.1 M507.1,380l-0.2-0.2l0.9-0.3L507.1,380"/>
            <polygon id="South_Carolina" className="st0" points="500.5,272.7 499,271.9 498.6,270.8 498.6,270.7 498.6,270.6 497.7,268.2 
    496.1,266.8 494.5,266.4 493.5,263.4 493.5,263.4 493.5,263.3 491.6,259.3 491.6,259.2 491.5,259.2 488.7,257.9 487.5,256.7 
    486.6,255 486.6,255 486.6,255 485.1,253.6 483.7,252.8 482.2,251 482.2,250.9 480.1,249.4 477.2,248.2 477,247.4 476.9,247.3 
    476.9,247.3 475.4,245.5 475.1,244.6 472.7,241 470.4,241.1 467.9,239.6 467.1,238.8 466.9,237.8 467.1,237.4 467.4,236.7 
    468.1,236.4 469,236 468.9,235.9 468.9,235.9 468.9,235.9 468.6,234.5 470,233.9 472.2,233 478.2,230 480.5,229.8 483.1,229.5 
    487.7,229.4 493.6,229.2 495.4,230.5 496.5,232.7 498.5,232.4 499.5,232.3 507.8,231.3 509.6,231.8 517.9,236.8 518.8,237.6 
    524.2,242 520.9,245.4 519.1,249.5 518.8,253.5 517.8,254.1 517.1,255.8 515.5,256.3 514.1,258.7 512.3,260.5 510.8,262.7 
    509.8,263.2 507.5,265.4 505.3,265.5 506,267.8 502.9,271.3 501.5,272.1 	"/>
            <polygon id="Mississippi" className="st0" points="400,309.7 399.4,308.3 398.7,305.7 397.9,305 396.5,303.7 396.8,302.1 397.2,300.1 
    396.6,299.3 395.3,299.4 390.3,300 383.1,300.1 374.1,300.2 374,299.8 373.7,298.9 374.3,293.5 375.7,291 376.4,289.6 379.8,283.8 
    379.5,282.7 379.5,282.3 380.2,281.9 380.6,280.4 380.6,280.4 380.6,280.4 379,279 379,277.8 378.9,277.7 378.9,277.6 378.4,276.5 
    377.8,274.9 377.7,271.1 377.7,271.1 377.7,271.1 378.6,269.5 378.4,267 377.5,265.6 377.3,265.2 378.2,264.3 378.3,264.2 
    377.2,262.5 377.5,261.5 377.6,261 377.6,260.9 378.5,257.3 380.2,255.9 379.9,254.8 379.8,254.4 382.1,251 382.5,250.8 384,250 
    383.9,248.8 383.7,247.7 385.5,244.2 387.1,243.4 387.1,243.4 387.1,243.4 387.1,243.3 387.2,242.9 411.3,240.6 411.3,241.2 
    411.2,244.3 411.3,255.3 410.8,275.7 410.7,284.9 412.5,297.3 413.5,306.5 410.4,306.5 409.5,306 408,305.8 403.5,307.1 
    402.3,306.5 400.5,309.4 	"/>
            <polygon id="Alabama" className="st0" points="421.2,308.3 420.1,308.2 421.5,307.2 421.5,306.5 421.5,306.4 419.4,302.3 417.6,301.8 
    416.6,304.9 415.8,306.5 415.6,306.4 415.6,306.4 414.1,306.4 414.2,306 414.1,305.9 413.9,306 414.1,305.9 413.2,297.1 
    411.4,284.8 411.5,275.6 412,255.2 411.9,244.2 411.8,241.2 411.8,240.5 425,239.2 440.4,238 440.4,239.2 440.6,240.6 441,242.8 
    443.2,248 444.8,254.5 444.8,254.5 445.8,258.6 446.8,261.7 447.8,266.3 449.2,270.5 450.9,272.7 451.2,275 452.5,275.6 
    452.6,276.7 451.4,279.9 451.1,282.1 451,283.4 452,286.3 452.2,289.7 451.7,291.4 452.2,292.1 453.1,292.6 453.2,294 449.7,293.8 
    445.2,294.2 428.4,296.1 421.5,297 421.2,299.3 422.5,300.6 424.1,301.8 424.3,306.9 	"/>
            <polygon id="Georgia" className="st0" points="491,298.6 489.9,298.2 489.8,297 489.7,296.3 489.7,296.3 489.7,296.2 486.1,296.1 
    482.5,296.5 468.3,297.4 461.2,297.8 456.1,297.5 455.1,296.6 453.8,294.6 453.9,294.6 453.6,292.3 452.6,291.7 452.3,291.3 
    452.8,289.8 452.6,286.2 451.5,283.3 451.6,282.1 451.9,280 453.1,276.8 453,275.2 451.7,274.7 451.4,272.5 449.7,270.3 
    448.3,266.2 447.4,261.6 446.3,258.4 445.3,254.4 443.7,247.9 441.5,242.7 441.1,240.5 441,239.1 441.1,237.9 450.5,236.8 
    453.8,236.5 453.8,236.5 453.9,236.5 454.3,236.4 454.3,236.4 468.1,234.7 468.4,235.7 467.9,235.9 467,236.3 466.6,237.2 
    466.4,237.8 466.6,239.1 467.6,240 470.2,241.6 472.4,241.6 474.6,244.7 474.9,245.7 474.9,245.7 474.9,245.7 476.5,247.6 
    476.8,248.6 476.9,248.6 476.9,248.7 479.9,249.9 481.9,251.3 483.3,253.2 483.3,253.2 483.4,253.3 484.9,254.1 486.2,255.3 
    487,257 487.1,257 487.1,257 488.5,258.4 491.2,259.6 493,263.6 494.1,266.8 494.1,266.8 494.2,266.9 495.9,267.4 497.3,268.7 
    498.1,270.8 498.6,272.3 498.6,272.3 498.6,272.4 500.5,273.4 501.6,273 501.8,273.5 499.7,277.6 500,279.3 499,282 497.5,288.7 
    498,292.4 494.9,292.2 492.6,292 491.1,291.8 490.7,292.3 490.1,293.1 491.1,295.9 	"/>

            <polyline id="Indiana" className="st0" points="409.7,146.3 410.7,146 
    412.1,144.7 413.7,143.8 416.6,143.6 418.2,143.5 431.6,142.1 431.6,142.1 435,141.8 435.8,149.5 436,152 436.1,153 438.8,176.3 
    439.2,180 439.1,180.3 438.9,181.6 439.3,182.2 439.7,182.7 439.8,183.4 438.5,184.3 438.3,184.4 436.5,185.2 436,185.4 
    433.7,185.8 433.5,187.9 433.3,189.1 430.2,191.3 428.5,193.9 428.5,194 428.5,194 428.4,194 428.7,195.5 428.7,195.6 428.4,196.4 
    426.5,196.4 426.1,196 425.4,195.3 425.4,195.3 425.4,195.3 423.6,196.2 421.7,197.2 421.7,197.3 421.6,197.3 421.7,199.2 
    421.4,199.2 421.2,198.8 421.2,198.7 419.7,197.8 419.5,197.6 419.5,197.6 419.5,197.6 417.2,198.5 417.2,198.6 417.2,198.6 
    416.2,200.4 415.6,200 415,199.4 414.5,198.9 411.5,199.2 407.9,199.9 407.9,199.9 407.8,199.9 406.9,200.4 406.2,200.7 
    406.2,199.3 406.5,196.6 406.5,196.4 408,194.5 408,194.5 408,194.4 409.2,191.8 410.9,189.1 410.6,185.2 409.4,183.3 409.2,181.3 
    409.7,177.7 409.4,173.1 408.5,162.6 408.5,162.6 407.7,152.5 407.1,145.1 	"/>
            <path id="Michigan__x2F__Bottom" className="st0" d="M414.4,143.2l1.7-1.6l0.2-1.9l1.6-3.4l0.6-4l0.5-3.1l-1-3.3l-1.9-4.3l-2.3-3.9
    l0.2-1.7l0.2-0.5l0,0l0.6-1.7l-0.3-0.8l-0.4-3l1-2.6l0.8-4.1l-0.5-1.6v-0.5l1.4-0.3l0.5-2l-0.4-0.5l0.1-0.5l1.1-1.2l1.9-0.8
    l0.6-2.8l1-0.4l0.3,0.3l-0.4,1.4l-0.9,1.6v3l0.6,0.2h0.1l2.6-1.5l-0.2-3.8l-0.2-1l0.2-1.3l0.9-1l1.6-0.2l2-0.6l-0.5-0.9l-1.5-0.3
    l-0.5-0.2l-0.2-2l1.8-1.4l1.7-1l1.9,1.4l0.8,0.4l0.5-0.5h1.6l3.6,2.2l3,1h1.6l0.8,1.6l1.5,1.4l-0.1,0.7l-0.9-0.2l-0.7,0.7l0.6,1.6
    l0.9,0.6l1,1.4l0.2,6.4l-1.6,1l-0.4,1.8l-0.5,2.1l-2.3,0.9v0.2c0,0-0.1,0.7-0.1,1.5c0,0.4-0.1,0.8-0.1,1.1c0,0.3-0.1,0.6-0.1,0.7
    l0,0l0,0l0,0v0.1c0,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.6,0.6,1,0.8c0.4,0.2,0.7,0.4,0.7,0.4l0,0l1.1,0.5l0.9-0.5l1.9-2.5l1.3-3.2
    l2.5-1.1l0.9-0.7l1.5,1.1l1.5,2.1l1.5,5.9l2.1,5.4l0.2,6.1l-1.1,0.5L456,127l-0.1-0.3v-0.1l-1-0.6l-1.2,2.3l-0.1,2.2l-0.6,0.2h-0.1
    l-1.2,1.2l0.1,3.3l-1.3,1.1l-1.4,2.5l-0.1,0.8l-6.5,1l-6.9,1.4v-0.8v-0.3l-5,0.7l-12.5,1.3l-1,0.1L414.4,143.2"/>
            <path id="Illinois" className="st0" d="M392.4,214.7l-1-1.2l-0.2-0.4l-0.7-2.6l0.5-1.5l0,0l0.1-0.2l-1.3-2.3l-1.2-3.1l-3.2-0.5
    l-4.4-3.6l-0.1-0.2l-1-2.4l0.5-2l0.1-0.4l1.2-3.1l0.5-2.6h-0.1l-1.5-0.8h-0.1l-0.3-0.2l-2.8-0.2l-1.2-0.1l-0.6-1v-0.5l0.1-2.1
    l-0.2-0.1v-0.1l-2.3-1.4l-1.3-0.8l-1.6-1.6l-3.1-3.5l-1.5-4.7l-0.1-2.7l0.4-1.2l1.2-0.9l0,0l0,0v-0.2l-0.1-1.3l0.1-0.8l0.9-0.7
    l1.1-0.3l0.3-0.1l-0.1-1.4v-0.6l0.4-1l1.2-1.1l0.1-1.4l0.1-1.5l0,0l0,0l-0.4-0.4l-0.8-0.7l-0.4-0.7v-0.1l0.3-0.8l0.5-1.4l0.1-0.2
    l3.3-0.9l1.3-0.5l1.6-1.3v-0.1l0,0l0.5-1.9l0.4-0.9l0.6-1.3l0.5-2.3l-0.5-1.6l-1-0.9l-1.5-1.3l-0.3-0.9l-0.1-0.3l-0.1-0.2l-0.6-0.7
    l-1.2-0.3h-0.2l-0.1-1.1l28.6-1.7l1-0.1l0.1,3.4l1,1.8l1,1.2l0.8,2.4l0.5,1.7l0.2,0.6l0.6,7.6l0,0l0.9,9.9l0.7,10.4l0.5,4.7
    l-0.5,3.5l0.1,2.3l1.3,2.3l0.2,3.3l-1.4,2.6v0.1l-0.1,0.1l-1.2,2.5l-1.5,1.9v0.1l0,0v0.3l-0.3,2.7v2c0,0.1,0,0.1-0.1,0.2
    c-0.1,0.2-0.2,0.5-0.2,0.7l-0.4,1.1l-1.1,0.7l0.5,1.5l0.1,0.2v0.1l0.3,0.7l-0.6,0.2l-1.7,0.4l-0.3,0.1l0,0H402l-1.3,1v0.2l-0.2,1.5
    l0.9,1.6l-0.1,0.8v0.5h-0.1l-0.4,0.1l-0.9-0.3l-1.4-0.5l-0.7-0.3l-1.1-0.5l-1,0.3l-0.6,0.2l-1.5,1.2l0,0l0,0l-0.1,0.2l-0.5,1
    l-0.4,0.3L392.4,214.7"/>
            <polygon id="Wisconsin" className="st0" points="373.2,135.1 372.6,134.5 372.5,134.4 372.5,134.3 372.4,134.3 370.4,134 369.9,133.7 
    368.8,133.2 367.3,130.8 367.3,130.8 367.2,130.3 367.1,128.9 368.5,126.6 368.5,126.6 368.5,126.6 368.3,125.5 368.3,125.5 
    368.3,125.5 367.3,124.8 366.8,124.4 366.3,122.1 365.9,118.4 365.8,117.5 365.8,117.5 365.8,117.4 364.3,115.5 364.3,115.5 
    364.3,115.5 359.7,112.8 357.1,109 356.5,108.9 354.9,108.7 354.3,107.8 353.6,106.6 351.4,106.6 349.5,104.8 349.3,100.5 
    349.2,99 349.2,98 350.4,95.9 349.9,93.6 348.2,92.2 348.1,92.2 349.5,88.5 352.9,86 353.3,85.4 353.8,84.9 353.7,81.4 353.7,79.2 
    353.9,77.5 354.7,76.4 355.1,75.9 355.5,76.3 355.6,76.3 357.6,76.7 359.6,76.2 364.5,74.1 366.4,72.8 367.6,72.3 368.6,73 
    368,73.6 366.7,75.7 366.2,77.1 367.1,77.8 368.4,77.1 369.1,77 369.1,77 369.8,76.8 370.2,77.5 371.2,77.6 371.6,77.4 373.1,79.4 
    374,80.6 374,80.6 374,80.6 379.3,81.4 384.7,82.8 385.1,83 386.5,83.5 394.4,85.3 395.3,86.2 395.7,86.8 397.2,87.3 398,87.6 
    399.3,94.4 400.5,95.3 400.9,95.6 400.3,96.9 399.2,98 397.8,101.1 396.9,102.7 397,104.2 398.3,104.5 399.1,103.1 400.4,102.6 
    401,101 402.2,99.9 404.1,97.2 404.1,97.2 406.8,93.1 407,93 407.1,93.3 407,94.7 405.1,99.1 403.3,102.9 403.6,105.6 403.1,107.4 
    403.7,108.3 403.6,109.9 402.3,111.5 402,112.8 402.4,115.2 402.4,115.2 402.8,117.3 401.9,119 401.4,120.9 400.7,123 401.5,124.6 
    401.9,128.7 402.7,131.6 402.7,133.4 401.6,133.5 	"/>
            <polygon id="Iowa" className="st0" points="365.8,166.8 365.2,166 364.3,164.9 363.5,163.2 320,164.9 319.9,164.9 319.7,164.9 
    318.4,162.1 318.2,158.1 318.2,157.8 318,157.2 317.2,155.1 316.8,151.7 315.9,150.4 315.2,149.2 314.8,147.3 314.7,146.7 
    314.7,146.6 314.6,146.1 313.1,141.9 312.8,141.2 312.2,137.9 312.1,137.9 312,137.6 311.1,136.1 310.1,134.4 311.4,131.3 
    312.3,127.4 310.5,126 310.2,124.4 310.7,122.9 311.7,122.9 365.8,122.1 365.8,122.2 365.9,122.5 366.3,124.7 366.3,124.7 
    366.3,124.7 367,125.2 367.8,125.8 368,126.5 366.6,128.7 366.7,130.4 366.8,131 366.9,131.1 368.5,133.7 369.7,134.2 370.2,134.5 
    372.2,134.8 372.5,135.2 372.7,137.1 372.7,137.1 372.7,137.2 373.2,137.3 374.3,137.6 374.7,138.2 374.8,138.3 374.9,138.4 
    375.1,139.5 376.8,140.9 377.7,141.7 378.1,143.2 377.6,145.3 377.1,146.5 376.5,147.7 376.1,149.2 374.8,150.2 373.7,150.5 
    370,151.7 369.9,151.8 369.9,151.8 369.9,151.8 369.8,152.2 369.4,153.1 369.3,153.5 368.9,154.5 369.1,154.8 369.4,155.6 
    370.3,156.4 370.6,156.6 370.5,157.9 370.4,159.1 369.3,160 368.8,161.2 368.8,161.9 368.8,162.9 367.7,163.2 366.6,164 366.3,165 
    366.5,166.3 	"/>
            <polygon id="Minnesota" className="st0" points="312.8,122.3 312.6,99.1 312.3,97 309.5,94.7 308.8,93.6 308.8,92.8 310.1,91.8 
    311.1,90.8 311.2,88.6 311.2,88.6 311,88.6 311.2,88.6 310.9,83 309.7,78.2 308.5,69.3 308.2,62.8 307,60.5 306,57.3 306,50.6 
    306.4,48 305.3,44.7 325,44.7 325.3,39.2 325.4,39.2 326.9,39.5 328,39.9 328.5,43.4 329.5,47.5 330.7,48.7 333.7,48.7 334,49.7 
    338.1,49.9 338.1,51.3 341.7,51.3 341.9,50.3 342.6,49.6 343.9,49.3 344.7,49.9 346.6,49.9 349.1,51.6 352.7,53.2 354.5,53.5 
    354.9,52.8 355.4,52.6 355.7,54.4 357.7,55.4 358.1,55 358.6,55.1 358.6,56.4 360.5,57.1 362.6,57.1 363.8,56.5 365.9,54.4 
    367.3,54.2 367.7,55.2 368.1,56.2 368.9,56.2 369,56.2 369.7,55.6 375.3,55.4 376.4,57.4 377,57.4 377.1,57.4 377.6,56.7 
    380.1,56.5 379.8,57.6 377.3,58.7 371.2,61.4 368.1,62.7 366,64.5 364.4,66.8 362.9,69.3 361.8,69.8 358.8,73.1 358.1,73.2 
    358,73.2 355.1,75.1 355,75.2 355,75.2 354.3,76.1 353.4,77.3 353.2,79.5 353.2,81.4 353.2,84.7 352.9,85.1 352.4,85.7 349.1,88.2 
    349.1,88.2 347.5,92.4 347.7,92.5 349.5,93.9 349.9,95.8 348.7,97.9 348.7,99.1 348.7,100.5 349,105 351.1,107.1 351.2,107.1 
    351.2,107.1 353.3,107.1 353.9,108 354.5,109.2 356.4,109.4 356.8,109.5 359.3,113.2 359.3,113.2 359.3,113.2 364,115.9 
    365.3,117.7 365.4,118.5 365.7,121.6 	"/>
            <path id="Michigan__x2F__Top" className="st0" d="M382,58.3l-0.7-0.5l1-1.1l1.4-0.5l3.5-2.5l1.3-0.3l0,0l-3.2,3.2l-2.1,1.3L382,58.3
      M440.1,80.5l-1.8-0.1l-0.4-1.6l-0.1-0.1l-0.1,0.2l0.1-0.2l-0.5-0.2l0.1-0.4l0.9-0.1l1.3-0.1c0.1,0.1,0.2,0.3,0.4,0.5
    c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.1,0.1,0.2,0.2l0.1,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.1,0,0.1L440.1,80.5
      M401.1,95.1l-0.3-0.3l-0.2-0.2l-0.8-0.6l-1.3-6.8l0,0l0,0l-1.1-0.4l-1.3-0.5l-0.4-0.4l-0.9-1.1l-8-1.8l-1.4-0.5l-0.5-0.2l-5.4-1.4
    l-5.1-0.7l-0.8-1.1l-1.5-2l0.3-0.2l1.8-0.5l2.5-1.6v-0.7l0.3-0.3l3.9-0.6l1.6-1.3l3-1.4l0.1-0.9l1.2-1.8l1.2-0.5l0.9-1.2l1.5-1.5
    l2.8-1.6l2.9-0.3l0.6,0.6l-0.1,0.4l-2.4,0.6l-1,2l-1.5,0.5l-0.3,1.7l-1.6,2.1l-0.2,1.9l0.8,0.5l0.8-0.9l2.1-1.8l0.8,0.8h1.6l2,0.6
    l0.9,0.7l0.9,2l1.9,1.9l2.7-0.1l0.9-0.6l1,0.8l1.2,0.4l0.9-0.6h0.7h0.1l1.1-0.7l2.6-2.3l2.2-0.7l4.4-0.2l3-1.3l1.6-0.8l0.7,0.1v3.6
    l0.4,0.3l0,0l2,0.6l1.3-0.3l4.1-1.1l0.7-0.7l0.6,0.2v4.5l2.2,2.1l0.9,0.4l0.4,0.3l-0.3,0.1l-0.5-0.2l0,0l-2.5-0.3l-1.4,0.4
    l-1.5-0.1l-2.1,1h-1.1l-3.8-0.9l-3.6,0.1L419,82l-4.5,0.4l-1.7,0.6l-0.8,2.1l-0.7,0.6h-0.1l-1-1.2l-3.1,1.7h-0.2l-0.8-1.1l-0.7,0.1
    h-0.1l-1.3,3l-0.6,2.7L401.1,95.1"/>

            <path id="North_Carolina" className="st0" d="M524.6,241.7l-5.6-4.5l-1-0.8l-8.3-5l-2-0.6l-8.3,1l-1,0.2l-1.6,0.2l-1.1-2.1l-1.9-1.3
    h-0.1l0,0l-6,0.2l-4.5,0.1H483h-0.2l-2.4,0.3l-2.3,0.2l0,0H478l-6,3l-2.2,0.9l-1.7,0.7l0,0l-13.6,1.6l0.3-2.2l1-0.8l1.1-0.3
    l0.8-0.2l0.4-2.5l2.7-1.7l1.8-0.7l0.8-0.3l0.9-0.8l1.8-1.5l1.5-0.7l1.5-0.7l0.1-0.5l0.3-1.6l1.9-1.9l0.6-0.6h0.1v0.1l0,0
    c0,0.1,0.1,0.2,0.2,0.3s0.3,0.2,0.5,0.2l0,0l0,0c0.2,0,0.4,0,0.7,0.1h0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.1,0l0.2,0.1l1.5-2.4
    l1.2-0.4l1.6,0.2l1.1-2.5l1.9-1.7l0,0l0,0v-0.2l0.3-1.3v-0.8l0.1-1.4h0.9l1.9,0.1l24.6-3.5l27.3-5.9l0.3-0.1l7.4-1.6l0.6-0.1
    l2.6-0.6l1.3,3l2.4,4.3l1.6,1.6l0.3,1.1l-1.9,0.1l0.9,0.7l-0.2,2.5l-1.7,0.8l-0.5,1.5l-0.8,1.8l-2.3,1l-1.5-0.2l-0.9-0.1l-1.6-1.3
    l0.4,1.5v0.6v0.2h1.4l0.4,0.6l-1.3,4.4h2.9l0.5,1.3l1.7-1.7l0.2-0.1l-1,1.8l-1.9,3.1h-0.7l-0.8-0.3l-1.9,0.4l-3.5,1.6l-4.3,3.5
    l-2.3,3.1l-1.3,4.3l-0.3,1.4l-2.9,0.3L524.6,241.7"/>
            <path id="West_Virginia" className="st0" d="M481,201.4l-0.5-0.1l-1.8-0.5l-1-1.1l-1.1-1.5l-0.5-0.3l-0.1-0.1l0,0l-1.4-0.9l0,0
    l-0.1-0.2l-1-2.1l-2.7-2.2h-0.1l-0.1-0.4l-0.3-1.1l-1-0.5l-0.8-0.5L468,189l-0.1-2.1v-1.1h1.2l0.9-0.4l0.5-0.2l0.1-1.9l1-1v-0.2
    l0.1-3.2l0.6-2.4l0.6-0.3l0.7,0.6l0.4,1.4h0.1l0,0l0.5-0.3l0.9-0.5l0.4-1.3l-0.6-1l-0.1-0.3v-0.2v-1.2l0.5-0.6l0.5-0.8l1.1-1.6
    l0.6-0.6l1.2,0.3l0.3-0.2h0.1l1.6-1.1l2.1-2.3l1.3-2.3l0.2-0.3v-0.1l0,0v-0.7l0.2-2.7l0.3-3.3v-0.3v-0.2v-3.1l-0.7-1.9l0,0l0.5-0.8
    l0.5-0.5l2.3,12.8h0.1l0,0h0.1l3-0.5l2.4-0.4l4.7-0.6l0.8-0.2l0.7,3.1l0.7,4.2l0,0v0.2l1.7-2.1l0.5-0.7l1-1.2l1.6-0.4l1-1l1.1-1.5
    l0.7,0.4l2.1-0.2l1.8-1.4l1.3-0.9l1.1-0.3l0.8,0.5l0,0l0,0l0.2,0.1l1.9,0.9l1.2,1.1l0.8,1l0.2,0.1c0,0.1,0,0.2,0,0.3
    c0,0.2,0,0.5,0,0.7L519,166v0.1l-3-1.2l-0.5-0.2l-3.1-1.2l0,0v0.1l-0.1,3.6l-1.1,2.3l-0.6,1.3l-0.6,0.6l-1.1,1l-0.4,0.9l-0.4,0.8
    l-0.8,0.2l-1,0.2l0,0l0,0l-1.3,4.9l-2.3,0.2l-0.2-0.2l-1.3-1.4h-0.1l0,0l-0.6,0.3l-0.4,0.2l-0.3,3.1l-0.1,0.6l-0.4,1.1l-1.5,3.6
    l-2,4.4l-0.2,0.4l0,0v0.1l0.6,0.8l-0.1,0.8l-0.1,0.3l-0.7,1l-1,1.3l-1.1-0.3l0,0l0,0l-1.1,0.8l-0.8,0.6l-0.5-0.1l-1.3-0.3l0,0l0,0
    l-0.1,0.3l-1.2,2.8c-0.1,0.1-0.4,0.2-0.7,0.3l-1.3,0.5c-0.2,0-0.3,0.1-0.4,0.1c0,0,0,0-0.1,0l-1.6-0.8l0,0l0,0l-1.6,0.9L482,201
    L481,201.4"/>
            <path id="Tennessee" className="st0" d="M387.1,242.4l0.1-1.3l1.6-1.1l-2.5-0.4l-0.8-2.3l1.8-1.5l0.4-1.4l0.8-2.7l0.7-2l0,0l0,0
    l0.1-0.1l1.1-3.7l0.9-4.4l0,0l0,0l5.1,0.1l4.6-0.4l2.7-0.1l2.9-0.1l0,0l0,0l0.1-2.7h2.3l3.1-0.3l10.4-1.2l18.7-1.8l15.4-1.5
    l2.5-0.6l2.4-0.6l2.5-0.4l9.8-1.3h0.9h4l1.7-1.3l1,0.1l-0.1,1.4l-0.1,0.8l-0.2,1.1l0,0l-1.9,1.7l-1,2.2l-1.4-0.2l-1.5,0.5l0,0l0,0
    l-1.4,2.3c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.5-0.1-0.8-0.1c-0.1,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.2v-0.1v-0.1l0,0l0,0v-0.3h-0.1
    l-0.6,0.1h-0.1l0,0l0,0l-0.6,0.6l-2.1,2l-0.3,1.7l-0.1,0.3l-1.2,0.6l-1.6,0.7l-1.8,1.6l-0.9,0.7l-0.7,0.3l-1.9,0.7l-2.8,1.9
    l-0.4,2.4l-0.5,0.1l-1.2,0.3h-0.1l0,0l-1.3,1.1l-0.2,2.4l-3.5,0.4l-25.4,2.4l-13.2,1.1l0,0L387.1,242.4"/>
            <polygon id="Ohio" className="st0" points="468,184.9 466.5,183.9 466,183.4 464.7,182.8 464.6,182.6 464.2,180.8 462.7,181.3 
    462.7,181.4 462.6,181.4 461,182.3 461,182.3 460.9,182.3 459.9,183.6 458.6,183.5 458.2,183.5 455.5,183.2 454.8,183 453.3,183.8 
    453.1,183.9 452.9,183.9 452.1,184 450.8,182.6 447.5,182.6 446.2,179.7 444.3,179 443.8,178.7 442.2,179.2 439.7,179.3 
    439.3,176.4 439.3,176.4 437.6,161 436.6,152.9 436.5,152 436.3,149.5 435.6,142.6 440.1,141.8 446.8,140.8 449.5,140.5 
    449.6,140.1 452.6,139.6 454.1,140.5 455.9,141.5 459.2,141.1 461.5,143.1 463,143 466.6,141.3 468.5,140.9 470.7,140.4 
    473.4,137.8 475.6,135.4 478.2,133.9 481.9,131.4 483.7,143.8 485.2,149.6 484.1,150.7 483.9,151.3 483.8,151.4 483.8,151.5 
    483.8,151.6 484.4,153.6 484.5,156.8 484.2,159.9 484.1,163 484,163.5 483.9,163.7 482.2,166.2 480.3,168.4 479.2,169.2 
    477.8,168.8 477.6,169.1 477.4,169 476.7,170.2 475.8,171.5 475.4,172.2 474.7,173.1 474.7,174.5 474.7,174.8 474.7,174.8 
    474.7,174.8 474.9,175.2 475.4,176 475.1,176.8 474.4,177.2 474.4,177.2 474.1,176.4 474.1,176.4 474.1,176.3 473,175.4 
    472.1,175.9 471.9,176 471.2,178.8 471.2,181.9 470.1,182.8 470,184.6 469.8,184.7 469.3,184.8 	"/>
            <path id="Kentucky" className="st0" d="M396.4,221h-4.7v-0.1l0.3-0.4l0.5-0.3l1.2-0.7v-0.7l0.1-1.5l0.4-0.7l0.3-0.5l0,0l0,0l-1.1-1.7
    l0.4-1l0.1-0.1l1.4-1.1l0.4-0.1l0.8-0.2l0.9,0.4l0.8,0.4l1.3,0.5l1,0.4l0,0l0,0l0.5-0.2l0.4-0.1l0,0l0,0l0.1-0.8l0.1-0.9l-0.8-1.6
    l0.2-1.3l1.1-0.9h0.2l1.5-0.4l0.2-0.1l0,0l1.1-0.4l0,0l0,0l-0.6-1.2l-0.4-1.2l0.9-0.6l0,0l0,0v-0.1c0-0.1,0.2-0.7,0.4-1.2l0.3-0.9
    l0,0l0,0v-0.1l0.9-0.5l1-0.5l3.5-0.6l2.8-0.3l0.3,0.3l0.6,0.7l1.3,0.7l1.1-2.2l1.9-0.8l1.3,0.9l0.2,0.5v0.1l0.1,0.2l0,0l0,0
    l1.2-0.3l-0.1-2l1.9-1.1l1.2-0.6l0.5,0.5l0.5,0.5h2.2h0.2l0.5-1.2l0,0l0,0v-0.1l-0.2-1.4l1.8-2.5l3.1-2.2l0.2-1.4l0.3-1.7l1.8-0.3
    l0.1-0.1l0,0l0.5-0.2l1.9-0.8l0.2-0.2l1.5-1l-0.1-1.1l-0.1-0.1v-0.1l-0.4-0.5l-0.3-0.4l0.2-1.1l0.1-0.5l2.5-0.1h0.1l0,0l1.5-0.5
    l0.3,0.1l1.7,0.7l1.4,2.9h3.4l1.2,1.4h0.1v0.1l1-0.1h0.2l0.3-0.2l1.4-0.7l0.6,0.1l0.9,0.1l1.8,0.2h0.1h0.3l1.6,0.1l1.2-1.5l1.6-0.9
    l1-0.3l0.3,1.2l0.1,0.4l0,0l0,0l1.3,0.7l0.7,0.5l1,0.8l0.1,1.6v2.1l0.6,1.1l0,0l0,0l0.9,0.5l0.7,0.4l0.3,0.9l0.1,0.5l0.2,0.1
    l2.6,2.1l1,2l0.2,0.4l0.1,0.1l1.3,0.9l-1.3,1.4l-2.4,2.6l-0.1,0.1l-3,3.2l-1.1,1.5v0.9v0.3l-2.8,1.4l-2.3,1.4l-1.7,0.9l0,0
    l-1.9,0.5l-2.5,0.6l-15.5,1.5l-18.7,1.8l-10.4,1.2l-3,0.3h-2.8l-0.1,2.7l-2.4,0.1h-2.8L396.4,221"/>
            <path id="Virginia_-_South" className="st0" d="M545.1,193.8l-3.8,1.1l-1.4-1.3l1.8-1l-0.3-1.4l-1.2-1.2l-0.1-0.9l-1.5-1l-0.1-1.5
    l1.8,0.1l0.1-0.5l-0.8-0.5l0.3-1.3l-0.9-0.7l0.3-3.1l-0.5-0.6l-2.2-0.3l-0.5-2l-1.1-0.2l-2-0.6h-0.9h-2l-1.9-0.5l-1.6-0.4l-0.3-0.9
    l-0.2-0.5v-0.3l-15.9-2.7l-1.3,1.1l-0.4,0.8l-0.4,1H508l0,0l-1.1,0.2l-0.7,0.1l-0.6,2.2l0,0l-0.7,2.8l0,0l0,0L502,180l-0.4-0.4
    l-1.1-1.1l-0.3,0.1l-0.2,0.1l-0.3,2.8l-0.1,0.8l-0.4,1l-0.4,1.2l-1.1,2.4l-1.5,3.5l-0.5,0.9l-0.1,0.2l0.6,0.8l-0.2,1v0.4l0,0v0.1
    l-0.8,1l-1.2,1.6l-0.4-0.1l-0.9-0.2l-1,0.7l-0.9,0.7l0,0l0,0l-0.7-0.2l-0.9-0.2L488,200l-0.9,0.3c-0.4,0.2-0.8,0.3-1.2,0.4
    c-0.1,0-0.1,0-0.1,0l-0.5,0.2l-0.3-0.2c0,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.5-0.3-0.6-0.3l-1.3,0.8h-0.1l0,0
    l-0.5,0.2l-1.2,0.5l-0.7-0.2l-1.9-0.5l-0.8-0.8l-0.4-0.5l-1.3-1.5l-3.8,4.1L469,206l-0.7,1.1v0.8v0.7l-5.4,2.9l-0.3,0.2l1.4-0.2
    l4.3-0.6l5.5-0.6l0,0h0.2h0.7l3.8-0.1l1.7-1.3l2.6,0.1h1.6l4.7-0.6l10.4-1.5l9.9-1.4l14.2-3.1l12.8-2.7l8.2-1.8l2.4-0.5
    L545.1,193.8z"/>
            <polygon id="Virginia_Penninsula" className="st0" points="544.6,189.8 543.8,187.2 544.1,183.7 545.1,181 545.6,178.9 545.6,178.8 
    545.4,178.9 545.6,178.8 545.6,177.8 546.7,177.3 549.2,176.3 548.8,177.9 546.9,180.4 546.6,183.5 546.9,185.7 545.7,188.9 	"/>

            <polygon id="New_Mexico" className="st0" points="167.7,287.2 158,285.9 169.3,208.1 169.3,208.1 169.3,208 237.3,214.2 236.8,221 
    236.7,221 236.1,221 235.8,220.9 234.6,233.9 232.1,270.7 230.7,282.6 216.4,281.9 213,281.6 193.1,279.7 188.1,279.4 187.2,279.3 
    187.2,279.3 187.1,279.3 187.7,282.5 168.5,280.6 "/>

            <path id="Hawaii_1_" className="st0" d="M162.5,344.4l-3.8-1.6l0.6-2.1l2.5-1.1l2.5,0.3l0.4,2l-1,2.4L162.5,344.4z M153.2,345.2h-1
	l1-1.9l1.2-0.2l0.1,0.2L153.2,345.2 M184.5,353.2l-1-0.9l-0.9,0.4l-1.4,0.2l-2.3-3.4l0.3-1l3.5-1.8l1.3,2.1l1.3,2.5l1.8,1.1
	l-0.4,0.6L184.5,353.2 M198.4,356.4l-2.8-0.2l-3.3-1l0.6-0.9l3,0.6l0.4-0.3l3.7,0.4l-0.1,0.5L198.4,356.4 M196.8,361l-1.2-2.3v-0.5
	l2.1,0.2l0.9,1.2l-0.2,0.8L196.8,361 M204.8,363.1l-1.5-0.9l-3.1-4.2l1.2-1.6l2.9,1.5l2.9,0.7l2.7,1.7v1l-2.2,1.1L204.8,363.1
	 M214.8,386.8l-1-0.1l-2.2-1.6l-0.2-2.8l0.4-1.6l-1.1-3.9l-1.4-1.2l-0.1-1.4l1.4-0.6l1.4-2.1l0.4-0.8l-1.1-1.2l-0.2-1.2l0.8-0.7
	l2.1,1l0,0l5,2.3l2.2,1.3l1,1.5l1.3,2.9l2.6,1.6l-0.2,0.6l-2.5,2L221,382l-1-0.4l-2.2,1.3l-1.6,2.2L214.8,386.8"/>
            <path id="Alaska" className="st0" d="M102.4,302.2l-0.2,56.1l1.1,0.6l2,0.1l1-0.7h1.7l0.1,1.9l4.6,4.5l0.3,1.7l2.2-1.3l0.4-0.1l0.2-2
	l1-1.1l0.7-0.1l1.3-1l2,1.4l0.4,1.9l1.3,0.7l0.7,1.6l2.6,1.2l2.2,3.9l1.8,2.6l1.5,1.8l1,2.4l3.3,1.2l3.4,1.4l0.6,2.9l0.3,2l-0.6,2.2
	l-1.2,1.5L137,389l-1-2l-1.8-1l-1.2-0.7l-0.5,0.5l1,1.8l0.1,2.4l-0.7,0.3l-1.3-1.3l-1.4-0.9l0.3,1.1l0.9,1.2l-0.5,0.5
	c0,0-0.5-0.2-0.9-0.6c-0.3-0.4-1.4-2.2-1.4-2.2l-0.6-1.5c0,0-0.2,0.9-0.6,0.6c-0.4-0.2-0.9-1-0.9-1l1.2-1.3l-1-1v-3.3h-0.5l-0.5,2.2
	l-0.7,0.3l-0.6-2.4l-0.4-2.4l-0.5-0.3l0.2,3.7v0.7l-1-0.9l-2.3-3.9l-1.4-0.3l-0.4-2.4l-1.1-1.9l-1.1-0.7V371l1.4-0.9l-0.3-0.2
	l-1.7,0.4l-2.2-1.6l-1.7-1.9l-3.2-1.7l-2.7-1.7l0.9-2.1v-1.1l-1.2,1.1l-1.9,0.7l-2.4-0.7l-3.7-1.6h-3.6l-0.4,0.3l-4.3-2.6l-1.4-0.2
	l-1.8-3.8l-2.3,0.2l-2.3,1l0.3,3l0.7-1.9l0.6,0.2l-1,2.9l2.1-1.8l0.4,1.1l-2.6,2.9l-0.9-0.2l-0.3-1.3L80,359l-0.9,0.7l-1.8-1.2
	l-2,1.4l-1.2,1.4l-2.2,1.4l-3.1-0.1l-0.3-1.4l2.4-0.4v-0.9l-1.5-0.4l0.6-1.6l1.5-2.6v-1.2l0.1-0.5l2.9-1.5l0.6,0.9h1.8l-0.9-1.7
	l-2.4-0.2l-3.3,1.8l-1.6,2.2l-1.2,1.7l-0.7,1.5l-2.8,1l-2,1.7l0,1l1.5,0.6L64,364l-1.8,2.1l-4.3,2.8l-5.1,2.8l-1.4,0.7l-3.5,0.7
	l-3.5,1.5l1.2,0.9l-1,1l-0.3,0.7l-1.8-0.6l-2.1,0.1l-0.5,1.5h-0.6l0.2-1.6l-2.3,0.9l-1.9,0.6l-2.2-0.9l-1.9,1.3h-2.1l-1.4,0.9
	l-1.1,0.5l-1.4-0.2l-1.7-0.7l-1.5,0.4l-0.6,0.6l-1.1-0.7V378l2-0.9l4.1,0.4l2.9-1.1l1.4-1.4l1.9-0.4l1.2-0.5l1.8,0.1l1.1,0.9
	l0.6-0.2l1.5-1.8l2-0.6L43,372l0.9-0.2l0.4,0.3h0.5l0.9-2.4l2.7-1l1.3-2.4l1.5-3l1.1-1l0.2-1.7l-1.1,0.9l-2.2,0.4l-0.4-1.6l-0.9-0.2
	l-0.6,0.6l-0.1,1.9l-1-0.1l-1-3.8l-0.9,0.9l-0.7-0.3l-0.2-1.3l-2.7,0.1l-1.4,0.7l-1.7-0.2l1-1l0.3-1.7l-0.4-1.3l1-0.6l0.9-0.1
	l-0.4-1.2v-2.9l-0.6-0.6l-0.5,1h-4l-1-0.9l-0.4-2.6l-1.4-2.3v-0.6l1.4-0.5l0.1-1.4l0.7-0.7l-0.5-0.3l-0.9,0.3l-0.7-1.8l0.6-3.3
	l3-2.1l1.7-1.1l1.3-2.4l1.8-0.9l1.7,0.7l0.2,1.6l1.6-0.2l2.1-1.6l1.1,0.4l0.6,0.4H49l1.5-0.9l0.5-2.9c0,0,0.2-1.9,0.6-2.2
	c0.4-0.3,0.6-0.6,0.6-0.6l-0.7-1.3l-1.7,0.5l-2.1,0.5l-1.3-0.3l-2.3-1.2l-3.3-0.1l-2.3-2.4l0.3-2.6l0.4-1.6l-1.4-1.2l-1.3-2.4
	l0.3-0.5l4.5-0.3h1.4l0.6,0.6h0.4l-0.1-1.1l2.6-0.4l1.7,0.2l1,0.7l-1,1.4l-0.3,1l1.8,1.1l3.3,1.2l1.2-0.6l-1.5-2.9l-0.6-2.1l0.6-0.5
	l-2.2-1.3l-0.3-0.7l0.3-1.1l-0.5-2.6l-1.9-3.1l-1.6-2.8l1.9-1.3h2.1l1.2,0.4l2.8-0.1l2.4-2.3l0.7-2l2.4-1.6l1.1,0.6l1.8-0.4l2.4-1.4
	l0.7-0.1l0.6,0.5l3-0.1l1.8-2h0.7l2.3,1.6l1.3,1.4l-0.3,0.7l0.4,0.7l1.1-1.1l2.6,0.2l0.2,2.4l1.3,1l4.7,0.4l4.1,2.8l1-0.6l3.4,1.7
	l1.4-0.4l1.3-0.5l3.2,1.3L102.4,302.2z M26.7,321.3l1.4,3.5l-0.1,0.6l-1.9-0.2l-1.2-2.7l-1.2-1h-1.6l-0.1-1.7l1.2-1.6l0.7,1.6l1,1
	L26.7,321.3z M25,343.3l2.4,0.5l2.4,0.6l0.5,0.6l-1.1,2.4l-2-0.1L25,345L25,343.3z M11.4,334l0.7,1.7l0.7,1.1l-0.7,0.5l-1.4-2V334
	H11.4z M2.3,382.1l2.2-1.5l2.2-0.6l1.7,0.2l0.3,1.1l1.3,0.3l1.3-1.3l-0.2-1.1l1.8-0.4l1.9,1.7l-0.7,1.2l-2.9,0.7l-1.8-0.3L7,381.3
	l-2.9,1L3,382.5L2.3,382.1z M34.6,379.1l1.1,1.3l1.4-1.1l-1-0.9L34.6,379.1z M36.5,381.1l0.7-1.5l1.4,0.2l-0.5,1.3H36.5z M52,379.9
	l1,1.2l0.6-0.7l-0.5-1.3L52,379.9z M57.7,371.7l0.7,3.8l1.9,0.5l3.3-1.9l2.9-1.7l-1.1-1.6l0.3-1.6l-1.4,0.9l-1.9-0.5l1.1-0.7
	l1.3,0.5l2.6-1.2l0.3-1l-1.6-0.5l0.5-1.3l-1.8,1.3l-3.1,2.3l-3.2,1.9L57.7,371.7z M85.6,358.6l1.6-1l-0.6-1.2l-1.2,0.6L85.6,358.6z"
            />
            <polygon id="South_Dakota" className="st0" points="311.8,138 311.8,137.7 309.9,134.5 311.1,131.4 312.1,127.5 310.2,126.1 310,124.3 
	310.5,122.7 312.6,122.7 312.5,119.4 312.3,99.5 311.9,97 309.2,94.9 308.6,93.8 308.5,92.7 309.9,91.7 310.9,90.6 311,88.8 
	272.7,87.8 236.7,85.5 233.2,127.4 242.8,128 255.9,128.8 267.5,129.4 283.2,130.3 291.1,130 292.4,131.5 295.8,133.6 296.3,134.1 
	299.3,133.1 303.6,132.7 304.7,133.6 307.4,134.7 309.4,135.7 309.6,136.7 310.3,138.2 "/>
            <polygon id="North_Dakota" className="st0" points="311,88.6 310.6,83.1 309.5,78.6 308.3,70.1 308,62.8 306.8,60.8 305.7,57.4 
	305.7,50.5 306.1,48 304.9,44.4 286.1,44 273.8,43.6 256.4,42.8 241.3,41.4 236.7,85.5 272.8,87.7 "/>
            <polygon id="Wyoming" className="st0" points="235.4,98.1 165.2,89.2 156,147.4 230.5,156.4 " />
            <polygon id="Montana" className="st0" points="241.3,41.3 221.1,39.5 201.8,37.1 182.6,34.5 161.3,31 149.2,28.7 127.7,24.2 124.7,38.2 
	127,43.2 126.1,46.2 127.3,49.2 129.4,50.1 132.4,57.2 134.2,59.3 134.5,60 136.8,60.8 137.1,62.1 132.4,73.7 132.4,75.4 
	134.1,77.5 134.7,77.5 137.8,75.5 138.3,74.8 139.3,75.2 139.2,78.7 141,86.9 142.9,88.6 143.5,89 144.7,90.6 144.4,92.8 
	144.9,95.1 145.6,95.7 147.1,94.2 148.9,94.2 151.1,95.2 152.7,94.6 155.4,94.6 157.8,95.7 159.6,95.4 159.9,93.4 161.9,93 
	162.8,93.9 163.1,96 164,96.5 165.3,89.3 235.5,98.1 "/>
            <polygon id="Nebraska" className="st0" points="323.6,173.7 320.9,173.7 292.3,173.1 265.5,172.5 251.1,172 251.6,163.4 251.9,158 
	230.6,155.6 233.4,127.6 243.4,128.3 256.6,129 256.6,129 268.3,129.8 284,130.6 291,130.3 292.2,131.7 295.4,133.7 296.3,134.3 
	299.2,133.4 301.8,133.1 303.5,133 304.6,133.8 307.3,134.9 309.2,135.9 309.4,136.9 310.1,138.4 311.5,138.4 311.7,138.4 
	312.3,141.3 312.6,142 314.2,146.7 314.3,147.4 314.6,149.4 315.5,150.6 316.2,151.8 316.7,155.3 317.5,157.4 317.7,158 
	317.7,158.2 317.9,162.3 319.3,165.5 319.4,165.5 319.7,166.3 320,166.8 320,167.8 319.9,168.4 321.4,170.6 322.3,172.1 
	323.3,173.2 "/>
            <polygon id="Oregon" className="st0" points="96.2,119.3 102.1,96.4 102.8,93.6 104.3,89.9 103.9,89.2 102.2,89.1 101.4,88 101.7,87.1 
	102,84.9 105,81.3 106.2,80.6 106.9,79.9 107.9,77.5 110.6,73.8 112.9,71.2 113.1,69 110.9,67.4 109.7,64.3 101.4,61.9 91.5,59.6 
	81.3,59.7 81,58.8 77.4,60.1 74.5,59.7 72.9,58.7 72.1,59.1 69,59 67.9,58.1 64.4,56.7 63.9,56.8 61,55.8 59.8,57 55.7,56.8 
	51.8,54.1 52.2,53.6 52.4,48.5 50.9,45.9 48.2,45.5 47.7,43.9 46.2,43.6 42.4,44.9 40.9,49.2 38.7,55.8 36.6,60 33.3,69.3 
	29.1,78.2 23.8,86.5 22.5,88.4 21.9,94.1 22.2,102 "/>
            <path id="Washington" className="st0" d="M65.6,8.9l2.9,1l6.4,1.8l5.6,1.3l13.2,3.7l15.1,3.7l10,2.1l-9,41.8l-8.2-2.3l-10.2-2.3h-10
	l-0.3-0.9l-3.7,1.4l-3-0.5l-1.4-1l-0.9,0.4L69,59l-1.1-0.9l-3.5-1.4l-0.5,0.1l-2.9-1L59.8,57l-4.1-0.2l-3.9-2.7l0.5-0.6l0.1-5
	L50.9,46l-2.7-0.4l-0.4-1.7l-1.5-0.3L44,44.4l-1.5-2.1l0.2-1.9l1.8-0.2l1.1-2.7l-1.7-0.7l0.1-2.4l2.9-0.4l-2-2l-1-4.7l0.4-1.9v-5.2
	l-1.2-2.1l1.5-6.2l1.4,0.3l1.6,1.9l1.8,1.7l2.1,1.3l3,1.4l2,0.4l1.9,1l2.2,0.6l1.5-0.1v-1.6l0.9-0.7l1.4-0.9l0.2,0.7l0.2,1.2
	l-1.5,0.3l-0.2,1.4l1.2,1l0.7,1.6l0.4,1.3l1-0.1l0.1-0.9L66,22.9l-0.3-2.1l0.5-1.2l-0.4-1v-1.5l1.2-2.3l-0.7-1.7l-1.6-3.2l0.2-0.5
	L65.6,8.9z M59.3,12.8l1.3-0.1l0.3,0.9l1-1.1h1.5l0.5,1l-1,1.1l0.4,0.5L63,16.5l-0.9,0.3c0,0-0.6,0.1-0.6-0.2c0-0.2,1-1.7,1-1.7
	l-1.1-0.4l-0.2,1l-0.5,0.4l-1-1.5L59.3,12.8z"/>
            <polygon points="32,208.1 65.6,174.5 53.8,156.8 66.3,112.3 22.2,102 21.2,105.1 21.1,110 17.7,117.8 15.6,119.5 15.4,120.2 
	14.3,120.7 13.3,123.5 12.8,125.6 14.6,128.4 15.6,131.2 16.4,133.5 16.2,137.8 15,139.8 14.6,143.6 13.9,146 15.1,148.6 
	16.9,151.6 18.4,154.8 19.3,157.4 19,159.5 18.8,159.9 18.8,161.3 22.6,165.4 22.2,167 21.8,168.5 21.4,169.8 21.5,175.2 
	22.9,177.6 24.1,179.3 26,179.6 26.6,181.5 25.8,183.8 24.5,184.9 23.7,184.9 23.2,187.4 23.5,189.3 25.6,192.2 26.7,195.7 
	27.7,198.8 28.5,200.8 30.7,204.6 31.7,206.3 "/>
            <polygon id="Colorado" className="st0" points="248.4,215 251.6,158.2 177,149.9 169,207.7 " />


            <polygon id="New_Jersey" className="st0" points="550,163.5 549.8,163.5 549.7,162 550.2,161.4 550.1,160.1 547.2,159.7 544.1,158.2 
  542.1,156.4 541.7,155.7 541.5,155 541.7,154 541.7,154 541.9,152.7 541.9,152.6 541.9,152.6 542.2,151.6 543,151.2 543.7,150.8 
  544,150.3 544.7,148.9 545.6,147.6 546.7,146.6 547.7,145.7 547.7,145.6 547.7,145.6 547.7,145 547.7,144.8 546,143.7 543.8,142.1 
  543.1,140.4 541.3,140.2 541.2,139.6 541.2,139.6 540.8,138 542.2,137.3 542.2,137.3 542.2,137.3 542.3,135.4 541.5,134.6 
  541.6,133.7 542.8,131.7 542.8,131.7 542.8,131.6 542.8,129.7 543,129.5 544.3,128.2 545.7,128.8 546.9,129.4 549.5,130.2 
  550.9,130.5 552.4,130.8 554.1,131.9 554.2,133 553.8,134.8 554.1,136.9 554.1,137.2 554.1,137.7 552.7,138.9 551.8,139.7 
  552.4,140.3 554.4,140.1 554.9,139.7 555.9,141.6 556,147.8 556.4,148.5 555.7,152 553.7,156.2 551.8,158.9 551.3,162 	"/>
            <path className="st0" d="M399.7,310.5l-0.9-1.9l-0.8-2.5l-2.3-2.2l0.7-3.7l-0.3-0.4l-1.1,0.1l-5,0.5l-16.6,0.3l-0.6-1.8l0.7-5.7l2.2-3.9
	l3.3-5.6l-0.4-1.7l0.9-0.5l0.2-1l0,0l0,0l-1.5-1.3l-0.1-1.4l-1.2-2.9l-0.1-3.9l0.9-1.6l-0.2-2.2l-1.2-2.1l1-1l-1-1.6l0.5-1.8
	l0.9-3.8l1.6-1.3l-0.4-1.5l2.5-3.7l1.8-0.9l-0.3-2.1l2-3.8l1.5-0.8l0.1-0.6l25-2.4v1.1l-0.1,3.2l0.1,10.9l-0.5,20.4l-0.1,9.3
	l1.8,12.3l1,9.8h-3.8l-1-0.5l-1.3-0.2l-4.5,1.3l-1.1-0.5l-1.7,2.7L399.7,310.5z M396.9,303.5l2.1,2l0.8,2.7l0.3,0.7l1.9-3l1.5,0.7
	l4.4-1.2l1.8,0.3l0.8,0.5h2.4l-0.9-8.7l-1.8-12.3l0.1-9.3l0.5-20.4l-0.1-10.9l0.1-3.1v-0.1l-23.2,2.3l-0.1,0.3l-0.2,0.1l-1.4,0.8
	l-1.7,3.2l0.4,2.5l-2.2,1l-2.1,3.1l0.5,1.6l-1.8,1.5l-1.2,4.8l1.2,1.9l-1,1l1,1.6l0.2,2.7l-0.9,1.7l0.1,3.5l1.2,2.9l0,0l0.1,1
	l1.6,1.4l-0.5,2l-0.6,0.3l0.3,1.3l-4.3,7.4l-1.3,2.4l-0.6,5.2l0.2,0.9l15.8-0.3l6.5-0.7l0.9,1.2L396.9,303.5z"/>

            <polygon className="st0 california" points="103.5,236.2 103.3,235.9 102.8,235.4 102,231.5 100.2,228.3 100.6,226.1 100.4,226 99.1,225.5 
	65.7,175.2 66.3,174.6 54.4,156.7 66.9,112 21.8,101.4 20.7,105 20.6,109.9 17.3,117.5 15.3,119.2 15.1,119.9 13.9,120.4 
	12.9,123.4 12.3,125.7 14.2,128.6 15.2,131.3 15.9,133.6 15.7,137.6 14.6,139.5 14.1,143.5 13.4,146.1 14.7,148.9 16.5,151.8 
	18,155 18.8,157.5 18.6,159.4 18.4,159.7 18.4,161.4 22.1,165.5 21.8,166.8 21.4,168.3 20.9,169.7 21,175.3 22.5,177.9 23.9,179.8 
	25.6,180.1 26.1,181.5 25.5,183.5 24.4,184.4 23.4,184.4 22.8,187.4 23.2,189.5 25.3,192.4 27.3,199 28.2,201.1 30.4,204.9 
	31.3,206.5 31.7,209.2 32.6,208.3 33.3,208.7 33.3,210.5 32.8,211.8 31.6,216.5 31.3,217.6 32.8,219.3 35.5,219.6 38.5,220.8 
	41,222.1 43,222.1 45,224.3 46.7,227.5 47.4,228.9 49.9,230.2 53.1,230.8 54.1,232.3 54.6,234.7 53.7,235.1 53.8,235.4 55.8,235.9 
	57.5,236 59.7,234.8 62.4,237.7 62.9,239.2 64.6,242 64.9,244.2 64.9,250.4 65.1,251.4 71.6,252.3 84.5,254.1 93.6,255 96.1,254.7 
	96.5,254.1 96.9,253.4 97.2,251.9 95,251.5 94.8,251.2 94.6,250.9 94.9,249.5 94.9,249.3 94.8,248.9 96,248.5 96.2,248.5 
	98.1,246.7 98.4,243.6 98.5,243.5 99.4,241.2 99.5,241.1 99.8,240.7 100.7,239.7 102.3,239 103.1,238.6 103.4,238.3 104,237.7 
	104.1,236.5 "/>
          </g>










        </svg>

      </Box>
    </HStack>
  )
}

export default LocationsMap
