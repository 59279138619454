import React from 'react'
import LocationsMap from './map'

export const Locations = (props: any) => {

  const { data } = props

  return (
    <div style={{
      margin: 'auto',
    }}>
      <LocationsMap locations={data} />
    </div>
  )

}